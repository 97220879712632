import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const CloudsIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.05853 10.8418C5.61686 10.6168 5.1252 10.5001 4.6252 10.5001C0.725195 10.7751 0.725195 16.4501 4.6252 16.7251H13.8669C14.9919 16.7335 16.0752 16.3168 16.9002 15.5585C19.6419 13.1668 18.1752 8.35846 14.5669 7.90012C13.2669 0.0917897 1.99186 3.05846 4.66686 10.5001M13.2085 8.26679C13.6419 8.05012 14.1169 7.93346 14.6002 7.92512"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloudsIcon;

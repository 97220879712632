import { AxiosError, isAxiosError } from "axios";

import { toastError, authErrors } from "utils";
import { IHttpRejection } from "types";

import { $http } from "./instance";

export interface IAuthCheckEmailData {
  email: string;
}

export interface IAuthVerifyData {
  email: string;
  verificationCode: string;
}

export interface IAuthSignUpData {
  email: string;
  verificationId: string;
  password: string;
}
export interface IAuthSignInData {
  email: string;
  password: string;
}
export interface IAuthResetPasswordData {
  verificationId: string;
  password: string;
}
export interface IAuthLogoutData {
  refreshToken: string;
}

export const Auth = {
  checkEmail: async (data: IAuthCheckEmailData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/signup/check-email`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
  signUpVerify: async (data: IAuthVerifyData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/signup/verify`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
  signUp: async (data: IAuthSignUpData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/signup`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
  refresh: async (): Promise<any> => {
    try {
      const res = await $http.post(`/refresh`);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
  signIn: async (data: IAuthSignInData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/login`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
  forgotPassword: async (data: IAuthCheckEmailData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/forgot-password`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
  forgotPasswordVerify: async (data: IAuthVerifyData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/forgot-password/verify`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
  resetPassword: async (data: IAuthResetPasswordData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/reset-password`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
  logOut: async (data: IAuthLogoutData): Promise<any> => {
    try {
      const res = await $http.post(`/auth/logout`, data);
      return res.data;
    } catch (e) {
      if (isAxiosError(e)) {
        const error = e as AxiosError<IHttpRejection>;
        const { message } = error.response?.data || {};
        message && toastError(authErrors(message));
      } else {
        toastError("Something went wrong");
      }
    }
  },
};

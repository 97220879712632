export const cs = (classes: Array<string | boolean | undefined>) =>
  classes.filter(Boolean).join(" ");

export const opacityTransitionConfig = (duration = 0) => ({
  defaultStyles: {
    opacity: 0,
    transition: `opacity ${duration || 200}ms ease-in-out`,
  },

  transitionStyles: {
    entering: {
      opacity: 0,
    },
    entered: {
      opacity: 1,
    },
    exiting: {
      opacity: 0,
    },
    exited: {
      opacity: 0,
    },
    unmounted: {
      opacity: 0,
    },
  },

  timeout: {
    enter: duration || 0,
    exit: duration || 200,
  },
});

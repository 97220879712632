import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const CheckInFlowerIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 24 / 24; // width / height
  let height = 24;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.37988 12L10.7899 14.42L15.6199 9.58001M10.7499 2.45001C11.4399 1.86001 12.5699 1.86001 13.2699 2.45001L14.8499 3.81001C15.1499 4.07001 15.7099 4.28001 16.1099 4.28001H17.8099C18.8699 4.28001 19.7399 5.15001 19.7399 6.21001V7.91001C19.7399 8.30001 19.9499 8.87001 20.2099 9.17001L21.5699 10.75C22.1599 11.44 22.1599 12.57 21.5699 13.27L20.2099 14.85C19.9499 15.15 19.7399 15.71 19.7399 16.11V17.81C19.7399 18.87 18.8699 19.74 17.8099 19.74H16.1099C15.7199 19.74 15.1499 19.95 14.8499 20.21L13.2699 21.57C12.5799 22.16 11.4499 22.16 10.7499 21.57L9.16988 20.21C8.86988 19.95 8.30988 19.74 7.90988 19.74H6.17988C5.11988 19.74 4.24988 18.87 4.24988 17.81V16.1C4.24988 15.71 4.03988 15.15 3.78988 14.85L2.43988 13.26C1.85988 12.57 1.85988 11.45 2.43988 10.76L3.78988 9.17001C4.03988 8.87001 4.24988 8.31001 4.24988 7.92001V6.20001C4.24988 5.14001 5.11988 4.27001 6.17988 4.27001H7.90988C8.29988 4.27001 8.86988 4.06001 9.16988 3.80001L10.7499 2.45001Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckInFlowerIcon;

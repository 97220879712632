import { FC, HTMLAttributes } from "react";
import { cs } from "utils";
import styles from "./styles.module.scss";

interface SwitcherProps extends HTMLAttributes<HTMLDivElement> {
  onClick: () => void;
  isActive: boolean;
  disabled?: boolean;
  someAdditionalProps?: any;
}

export const Switcher: FC<SwitcherProps> = ({
  className,
  onClick,
  isActive,
  disabled,
  someAdditionalProps,
}) => {
  return (
    <div
      className={cs([styles.Switcher, className, isActive && styles.active])}
      onClick={onClick}
      disabled={disabled}
      {...someAdditionalProps}
    >
      <div className={styles.circle} />
    </div>
  );
};

import { useState } from "react";

import { Icon, Modal } from "components/UI";
import { ChangeLanguageModal } from "components";

import { cs } from "utils";

import styles from "./styles.module.scss";

export const ChangeLanguage = () => {
  const [isChangeLangModal, setIsChangeLangModal] = useState<boolean>(false);

  const handleOpenLangModal = () => {
    setIsChangeLangModal(true);
  };

  const handleCloseLangModal = () => {
    setIsChangeLangModal(false);
  };

  return (
    <>
      <Modal isShowed={isChangeLangModal} onClose={handleCloseLangModal}>
        <ChangeLanguageModal onClose={handleCloseLangModal} />
      </Modal>
      <div
        className={cs([styles.ChangeLanguage, styles.item])}
        onClick={handleOpenLangModal}
      >
        <Icon name="global" size={18} /> Change language
      </div>
    </>
  );
};

import { FC } from "react";

import { Icon } from "components/UI";
import { palette } from "utils";

import styles from "./styles.module.scss";

type SidebarTipProps = {
  onClose: () => void;
};

export const SidebarTip: FC<SidebarTipProps> = ({ onClose }) => {
  return (
    <div className={styles.SidebarTip}>
      <div className={styles.container}>
        <h2 className={styles.title}>Nice to know</h2>
        <Icon
          name="close"
          action={onClose}
          className={styles.closeIcon}
          color={palette.grey80}
        />
        <p className={styles.text}>
          You also can manage folders directly in sidebar
        </p>
      </div>
    </div>
  );
};

import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import { useAppDispatch } from "store";
import { setEmail as setStoreEmail, setForgotEmail } from "store/auth";
import { Auth } from "api";

import { Input, Button } from "components/UI";
import { PATHES } from "constants/pathes";
import { validateEmail } from "utils";

import styles from "./styles.module.scss";

export const SignUp: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleChangeEmail = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setError("");
    const emailValidationErrors = value && validateEmail(value);
    if (emailValidationErrors) {
      setError(emailValidationErrors[0]);
    }
    setEmail(value);
  };

  const handleClearEmail = () => {
    setError("");
    setEmail("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email && !error) {
      dispatch(setForgotEmail(""));
      dispatch(setStoreEmail(email));
      await Auth.checkEmail({ email });
      navigate(PATHES.ENTER_CODE);
    }
  };

  return (
    <form className={styles.SignUp}>
      <h1 className={styles.title}>Sign up</h1>
      <Input
        type="email"
        label="Email"
        onChange={handleChangeEmail}
        value={email}
        name="email"
        error={error}
        iconEnd={email ? "close-in-circle" : undefined}
        iconEndType="stroke"
        iconEndAction={handleClearEmail}
      />
      <div className={styles.agreement}>
        By pressing the sign up bellow you agree to our{" "}
        <NavLink to={PATHES.TERMS} className={styles.link}>
          Terms of Service
        </NavLink>{" "}
        and{" "}
        <NavLink to={PATHES.PRIVACY_POLICY} className={styles.link}>
          Privacy Policy.
        </NavLink>
      </div>
      <Button
        variant="primary"
        title="Sign up"
        onClick={handleSubmit}
        isDisabled={!email || !!error}
        className={styles.submitButton}
      />
      <div className={styles.info}>
        <span>Already have an account?</span>
      </div>
      <Button
        variant="textBlack"
        size="sm"
        title="Log in"
        onClick={() => navigate(PATHES.SIGN_IN)}
        className={styles.logInButton}
        iconStart="login"
        iconStartType="stroke"
      />
    </form>
  );
};

import { FC, MouseEvent } from "react";

import { ContextMenu } from "components";
import { Icon } from "components/UI";
import { IconName } from "components/UI/Icon";
import { MenuItem } from "types";

import styles from "./styles.module.scss";

enum RowActionsEnum {
  "share",
  "move",
  "download",
  "rename",
  "three-dots-horizontal",
}

type Points = {
  x: number;
  y: number;
};

type RowActionsProps = {
  actions: IconName[];
  additionalActions: MenuItem[];
  isRowMenu: boolean;
  points: Points;
  handleSetRowMenu: (e: MouseEvent<HTMLElement>) => void;
};

export const RowActions: FC<RowActionsProps> = ({
  actions,
  additionalActions,
  isRowMenu,
  points,
  handleSetRowMenu,
}) => {
  const actionContent: Record<keyof typeof RowActionsEnum, string> = {
    share: "Share",
    move: "Move",
    download: "Download",
    [`three-dots-horizontal`]: "Show more",
    rename: "Rename",
  };

  return (
    <div className={styles.RowActions}>
      {actions.map((el, index) => (
        <Icon
          key={index}
          name={el}
          className={styles.action}
          dataTooltipId={el}
          dataTooltipContent={actionContent[el as keyof typeof RowActionsEnum]}
        />
      ))}
      <div className={styles.rowMenu}>
        <div onClick={handleSetRowMenu}>
          <Icon
            name="three-dots-horizontal"
            className={styles.action}
            dataTooltipId={isRowMenu ? "" : "show_more"}
            dataTooltipContent="Show more"
            dataTooltipPlace="bottom"
          />
        </div>
        {isRowMenu && (
          <ContextMenu
            theme="light"
            items={additionalActions}
            top={points.y + 20}
            left={points.x - 15}
            isFixed
          />
        )}
      </div>
    </div>
  );
};

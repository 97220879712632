import { Routes, Route, Navigate } from "react-router-dom";
import { PATHES } from "constants/pathes";
import { Dashboard, Account, Settings } from "pages";
import { Sidebar, PdfViewerComponent } from "components";
import { useAppSelector, dashboard } from "store";

export const Private = () => {
  const { sidebarWidth, document } = useAppSelector(dashboard);

  return (
    <>
      {document && <PdfViewerComponent document={document} />}
      <Sidebar />
      <main style={{ marginLeft: `${sidebarWidth}px` }}>
        <Routes>
          <Route path={PATHES.HOME} element={<Dashboard />} />
          <Route path={PATHES.HELP} element={<div>Help</div>} />
          <Route path={PATHES.TRASH} element={<div>Trash</div>} />
          <Route path={PATHES.CONTRACTS} element={<div>Contracts</div>} />
          <Route path={PATHES.ACCOUNT} element={<Account />}>
            <Route path={PATHES.SETTINGS} element={<Settings />} />
            <Route
              path={PATHES.MY_SIGNATURES}
              element={<div>Signatures</div>}
            />
            <Route
              path={PATHES.SUBSCRIPTION}
              element={<div>Subscription</div>}
            />
          </Route>
          <Route path="*" element={<Navigate to={PATHES.HOME} replace />} />
        </Routes>
      </main>
    </>
  );
};

import { Routes, Route, Navigate, useNavigate, Link } from "react-router-dom";

import { PATHES } from "constants/pathes";

import {
  SignIn,
  SignUp,
  EnterCode,
  ForgotPassword,
  CreatePassword,
} from "components";
import { Icon } from "components/UI";

import mainLogoBlack from "assets/img/main-logo-black.png";

import styles from "./styles.module.scss";

export const Public = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.Public}>
      <Link to={PATHES.SIGN_IN} className={styles.link}>
        <img src={mainLogoBlack} alt="main-logo" width="124" height="33" />
      </Link>
      <Icon
        name="chevron-left-empty"
        action={handleGoBack}
        className={styles.backButton}
      />
      <Routes>
        <Route path={PATHES.SIGN_IN} element={<SignIn />} />
        <Route path={PATHES.SIGN_UP} element={<SignUp />} />
        <Route path={PATHES.ENTER_CODE} element={<EnterCode />} />
        <Route path={PATHES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={PATHES.CREATE_PASSWORD} element={<CreatePassword />} />
        <Route path={PATHES.TERMS} element={<div>Terms of Service</div>} />
        <Route
          path={PATHES.PRIVACY_POLICY}
          element={<div>Privacy Policy</div>}
        />
        <Route path="*" element={<Navigate to={PATHES.SIGN_IN} replace />} />
      </Routes>
    </div>
  );
};

import { FC, memo, ChangeEvent } from "react";

import { cs, palette } from "utils";
import { Icon } from "../Icon";

import styles from "./styles.module.scss";

type CheckboxProps = {
  title?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  isDisabled?: boolean;
  isChecked: boolean;
  isWhite?: boolean;
  size?: number;
  className?: string;
};

export const Checkbox: FC<CheckboxProps> = memo(
  ({
    title,
    name,
    onChange,
    onClick,
    isDisabled,
    isChecked,
    isWhite,
    size,
    className = "",
  }) => (
    <label
      className={cs([
        styles.Checkbox,
        title && styles.withTitle,
        isDisabled && styles.disabled,
        isWhite && styles.white,
        className,
      ])}
      htmlFor={`${name || title}checkboxComponent`}
    >
      <input
        id={`${name || title}checkboxComponent`}
        type="checkbox"
        className={styles.hiddenCheckbox}
        name={name || title}
        checked={isChecked}
        onChange={onChange}
        onClick={onClick}
        disabled={isDisabled}
      />
      <span
        className={styles.checkmark}
        style={{
          width: size ? `${size}px` : "20px",
          height: size ? `${size}px` : "20px",
        }}
      >
        {isChecked && (
          <Icon
            name="check"
            color={isWhite ? palette.white : palette.black}
            size={size ? size / 2 : 8}
          />
        )}
      </span>
      {title && <span className={styles.title}>{title}</span>}
    </label>
  ),
);

import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const UserEmptyIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 12 / 17; // width / height
  let height = 17;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.12004 7.81412C6.04504 7.80662 5.95504 7.80662 5.87254 7.81412C4.08754 7.75412 2.67004 6.29162 2.67004 4.49162C2.67004 2.65412 4.15504 1.16162 6.00004 1.16162C7.83754 1.16162 9.33004 2.65412 9.33004 4.49162C9.32254 6.29162 7.90504 7.75412 6.12004 7.81412Z"
        stroke={color || palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.37004 10.5816C0.555039 11.7966 0.555039 13.7766 2.37004 14.9841C4.43254 16.3641 7.81504 16.3641 9.87754 14.9841C11.6925 13.7691 11.6925 11.7891 9.87754 10.5816C7.82254 9.20912 4.44004 9.20912 2.37004 10.5816Z"
        stroke={color || palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserEmptyIcon;

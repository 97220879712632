// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/ClashDisplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/ClashDisplay-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/fonts/Urbanist-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/fonts/Urbanist-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Clash";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Clash";
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Urbanist";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Urbanist";
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
.styles_Public__jNkFS {
  width: 358px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
}
.styles_Public__jNkFS .styles_link__NhVuH {
  margin: 0 auto 137px;
}
.styles_Public__jNkFS .styles_backButton__BNaBX {
  margin-bottom: 16px;
  cursor: pointer;
}
.styles_Public__jNkFS .styles_backButton__BNaBX:hover path {
  stroke: #706f6f;
}`, "",{"version":3,"sources":["webpack://./src/scss/_fonts.scss","webpack://./src/router/Public/styles.module.scss","webpack://./src/scss/_palette.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,gBAAA;EACA,+DAAA;ACCF;ADEA;EACE,oBAAA;EACA,gBAAA;EACA,+DAAA;ACAF;ADGA;EACE,uBAAA;EACA,gBAAA;EACA,+DAAA;ACDF;ADIA;EACE,uBAAA;EACA,gBAAA;EACA,+DAAA;ACFF;AAjBA;EACE,YAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;AAmBF;AAjBE;EACE,oBAAA;AAmBJ;AAhBE;EACE,mBAAA;EACA,eAAA;AAkBJ;AAhBI;EACE,eCMI;ADYV","sourcesContent":["@font-face {\n  font-family: \"Clash\";\n  font-weight: 400;\n  src: url(\"../assets/fonts/ClashDisplay-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Clash\";\n  font-weight: 500;\n  src: url(\"../assets/fonts/ClashDisplay-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Urbanist\";\n  font-weight: 400;\n  src: url(\"../assets/fonts/Urbanist-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Urbanist\";\n  font-weight: 500;\n  src: url(\"../assets/fonts/Urbanist-Medium.ttf\") format(\"truetype\");\n}\n","@import \"scss/resources\";\n\n.Public {\n  width: 358px;\n  margin: 50px auto;\n  display: flex;\n  flex-direction: column;\n\n  & .link {\n    margin: 0 auto 137px;\n  }\n\n  & .backButton {\n    margin-bottom: 16px;\n    cursor: pointer;\n\n    &:hover path {\n      stroke: $grey-80;\n    }\n  }\n}\n","$white: #fefefe;\n$black: #121212;\n\n$red: #f1403e;\n$red-20: #fff4f4;\n\n$peach: #f87238;\n$peach-20: #fff6ed;\n\n$green: #47a80c;\n$green-20: #f4feed;\n$green-action: #7df644;\n$green-system: #47A80C;\n\n$blue: #2986dc;\n$blue-20: #f3f8ff;\n\n$grey-10: #f8f8f8;\n$grey-20: #f5f5f5;\n$grey-30: #f0f0f0;\n$grey-40: #d9d9d9;\n$grey-50: #b9b9b9;\n$grey-70: #989898;\n$grey-80: #706f6f;\n$grey-90: #484848;\n\n$linear-black: linear-gradient(139.68deg, #292929 14.67%, #181818 85.39%);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Public": `styles_Public__jNkFS`,
	"link": `styles_link__NhVuH`,
	"backButton": `styles_backButton__BNaBX`
};
export default ___CSS_LOADER_EXPORT___;

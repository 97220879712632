import { FC } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { Icon } from "components/UI";
import { palette, cs, menuTitle } from "utils";
import { MenuItem } from "types";

import styles from "./styles.module.scss";

type ContextMenuProps = {
  onClose?: () => void;
  items: MenuItem[];
  theme: "dark" | "light";
  top?: number;
  left?: number;
  isFixed?: boolean;
};

export const ContextMenu: FC<ContextMenuProps> = ({
  onClose = () => {},
  items,
  theme,
  top = "auto",
  left = "auto",
  isFixed,
}) => {
  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div
        className={cs([styles.ContextMenu, theme === "light" && styles.light])}
        style={{
          position: isFixed ? "fixed" : "absolute",
          top: `${top}px`,
          left: `${left}px`,
          right: isFixed ? "auto" : "10px",
        }}
      >
        <ul className={styles.menuList}>
          {items.map((el, index) => (
            <li
              key={index}
              className={cs([
                styles.menuItem,
                theme === "light" && styles.light,
              ])}
              onClick={onClose}
            >
              <Icon
                name={el}
                size={14}
                color={theme === "light" ? palette.black : palette.grey50}
              />
              {menuTitle[el]}
            </li>
          ))}
        </ul>
      </div>
    </OutsideClickHandler>
  );
};

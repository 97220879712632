import { SelectedType } from "types";

type FolderItem = {
  value: SelectedType;
  title: string;
};

export const folderItems: FolderItem[] = [
  {
    title: "Certified files",
    value: "PDF_CERTIFIED",
  },
  {
    title: "Original files",
    value: "PDF",
  },
  {
    title: "Folders",
    value: "FOLDER",
  },
];

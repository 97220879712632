import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector, auth } from "store";
import {
  setAuthenticated,
  setEditablePassword,
  setEditableEmail,
} from "store/auth";

import { PATHES } from "constants/pathes";
import { Input, Icon, Modal } from "components/UI";
import { ConfirmPasswordModal } from "components";

import { cs, toastSuccess } from "utils";

import styles from "./styles.module.scss";

export const EmailPassword = () => {
  const { editablePassword, editableEmail, user } = useAppSelector(auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isConfirmPasswordModal, setIsConfirmPasswordModal] =
    useState<boolean>(false);
  const [editable, setEditable] = useState<string>("");

  useEffect(() => {
    if (editablePassword) {
      toastSuccess("Password was successfully updated");
      dispatch(setEditablePassword(false));
    }
  }, [dispatch, editablePassword]);

  useEffect(() => {
    if (editableEmail) {
      toastSuccess("Email was successfully updated");
      dispatch(setEditableEmail(""));
    }
  }, [dispatch, editableEmail]);

  const openEditPasswordModal = () => {
    setIsConfirmPasswordModal(true);
    setEditable("password");
  };

  const openEditEmailModal = () => {
    setIsConfirmPasswordModal(true);
    setEditable("email");
  };

  const handleClosePasswordModal = () => {
    setIsConfirmPasswordModal(false);
    setEditable("");
  };

  const preventChange = (e: ChangeEvent) => {
    e.preventDefault();
  };

  const changePassword = () => {
    dispatch(setAuthenticated(false));
    dispatch(setEditablePassword(true));
    navigate(PATHES.CREATE_PASSWORD);
  };

  const changeEmail = () => {
    dispatch(setAuthenticated(false));
    dispatch(setEditableEmail("old"));
    navigate(PATHES.FORGOT_PASSWORD);
  };

  return (
    <>
      <Modal
        isShowed={isConfirmPasswordModal}
        onClose={handleClosePasswordModal}
      >
        <ConfirmPasswordModal
          onClose={handleClosePasswordModal}
          onSubmit={editable === "password" ? changePassword : changeEmail}
        />
      </Modal>

      <div className={cs([styles.EmailPassword, styles.item])}>
        <div className={styles.inputWrap}>
          <Input
            className={styles.input}
            type="email"
            placeholder={user?.email || "davidsmith@aposti.com"}
            name="email"
            label="Email"
            onChange={preventChange}
          />
          <Icon
            name="edit"
            className={styles.edit}
            action={openEditEmailModal}
          />
        </div>
        <div className={styles.inputWrap}>
          <Input
            className={styles.input}
            type="text"
            placeholder="*************"
            name="password"
            label="Password"
            onChange={preventChange}
          />
          <Icon
            name="edit"
            className={styles.edit}
            action={openEditPasswordModal}
          />
        </div>
      </div>
    </>
  );
};

import { FC } from "react";
import { Provider } from "react-redux";
import { ToastContainer, Slide } from "react-toastify";

import { store } from "store";
import Router from "router";

import "react-toastify/dist/ReactToastify.min.css";
import "scss/index.scss";
import styles from "./styles.module.scss";

const App: FC = () => {
  return (
    <>
      <Provider store={store}>
        <Router />
        <ToastContainer
          toastClassName={styles.Toast}
          bodyClassName={styles.ToastBody}
          position="top-center"
          autoClose={5000}
          draggable={false}
          closeButton={false}
          theme="colored"
          transition={Slide}
          limit={4}
          hideProgressBar
        />
      </Provider>
    </>
  );
};

export default App;

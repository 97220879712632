import { FC, ReactElement } from "react";
import { cs } from "utils";

import { Icon, IconName } from "../Icon";

import styles from "./styles.module.scss";

type ButtonProps = {
  onClick: (value?: any) => void;
  title: string | ReactElement;
  className?: string;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg";
  variant?: "textWhite" | "textBlack" | "outlined" | "secondary" | "primary";
  iconStart?: IconName;
  iconEnd?: IconName;
  iconStartType?: "stroke" | "fill";
  iconEndType?: "stroke" | "fill";
  iconStartAction?: () => void;
  iconEndAction?: () => void;
};

export const Button: FC<ButtonProps> = ({
  onClick,
  title,
  className,
  isDisabled,
  size,
  variant,
  iconStart,
  iconEnd,
  iconStartType,
  iconEndType,
  iconStartAction,
  iconEndAction,
}) => {
  return (
    <button
      className={cs([
        styles.Button,
        size && styles[size],
        variant && styles[variant],
        className,
      ])}
      onClick={onClick}
      disabled={isDisabled}
    >
      {iconStart && (
        <Icon
          name={iconStart}
          size={size === "sm" ? 18 : 20}
          className={iconStartType && styles[iconStartType]}
          action={iconStartAction}
        />
      )}
      {title}
      {iconEnd && (
        <Icon
          name={iconEnd}
          size={size === "sm" ? 18 : 20}
          className={iconEndType && styles[iconEndType]}
          action={iconEndAction}
        />
      )}
    </button>
  );
};

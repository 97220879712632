import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSession } from "api";
import { IAuthUser } from "types";

export type AuthState = {
  isAuthenticated: boolean;
  email: string;
  editablePassword: boolean;
  editableEmail: string;
  forgotEmail: string;
  verificationId: string;
  user: IAuthUser | null;
};

const initialState: AuthState = {
  isAuthenticated: !!getSession().refreshToken,
  email: "",
  editablePassword: false,
  editableEmail: "",
  forgotEmail: "",
  verificationId: "",
  user: getSession().user || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated(state, { payload }: PayloadAction<boolean>) {
      state.isAuthenticated = payload;
    },
    setEmail(state, { payload }: PayloadAction<string>) {
      state.email = payload;
    },
    setEditablePassword(state, { payload }: PayloadAction<boolean>) {
      state.editablePassword = payload;
    },
    setEditableEmail(state, { payload }: PayloadAction<string>) {
      state.editableEmail = payload;
    },
    setForgotEmail(state, { payload }: PayloadAction<string>) {
      state.forgotEmail = payload;
    },
    setVerificationId(state, { payload }: PayloadAction<string>) {
      state.verificationId = payload;
    },
    setUser(state, { payload }: PayloadAction<IAuthUser>) {
      state.user = payload;
    },
  },
});

export const {
  setAuthenticated,
  setEmail,
  setEditablePassword,
  setEditableEmail,
  setVerificationId,
  setUser,
  setForgotEmail,
} = authSlice.actions;

export default authSlice.reducer;

import { NavLink, Outlet } from "react-router-dom";

import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const Account = () => {
  return (
    <div className={styles.Account}>
      <h1 className={styles.title}>Account</h1>
      <header className={styles.header}>
        <ul className={styles.links}>
          <li>
            <NavLink
              to={PATHES.SETTINGS}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.MY_SIGNATURES}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              My signatures
            </NavLink>
          </li>
          <li>
            <NavLink
              to={PATHES.SUBSCRIPTION}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              Subscription
            </NavLink>
          </li>
        </ul>
      </header>
      <Outlet />
    </div>
  );
};

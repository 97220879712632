import { FC, useEffect, useRef } from "react";

type PdfViewerComponentProps = {
  document: string;
};

export const PdfViewerComponent: FC<PdfViewerComponentProps> = ({
  document,
}) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let PSPDFKit: any;
    let instance;

    (async function createContainer() {
      PSPDFKit = await import("pspdfkit");

      const initialViewState = new PSPDFKit.ViewState({
        scrollMode: PSPDFKit.ScrollMode.PER_SPREAD,
        layoutMode: PSPDFKit.LayoutMode.AUTO,
        pageSpacing: 10,
      });

      const toolbarItems = PSPDFKit.defaultToolbarItems;
      // Add the `layout-config` toolbar item after the `pager` item.
      const pagerIndex = toolbarItems.findIndex(
        (item: any) => item.type === "pager",
      );
      toolbarItems.splice(pagerIndex + 1, 0, { type: "layout-config" });

      PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        initialViewState,
        toolbarItems,
        enableClipboardActions: true,
        enableHistory: true,
      });

      const widget = new PSPDFKit.Annotations.WidgetAnnotation({
        pageIndex: 0,
        boundingBox: new PSPDFKit.Geometry.Rect({
          left: 50,
          top: 50,
          width: 200,
          height: 50,
        }),
        formFieldName: "My univd",
        id: PSPDFKit.generateInstantId(),
      });
      const formField = new PSPDFKit.FormFields.SignatureFormField({
        name: "My univd",
        annotationIds: PSPDFKit.Immutable.List([widget.id]),
      });
      instance.create([widget, formField]);

      //   async function savePagePNG(pageIndex) {
      //     // Get page width.
      //     const { width } = instance.pageInfoForIndex(pageIndex);

      //     // Render page.
      //     const src = await instance.renderPageAsImageURL({ width }, pageIndex);

      //     // Save the image as a PNG.
      //     saveAs(src, `pdf-page-${pageIndex}.png`);

      //   }

      //   savePagePNG(1);
      // const textLines = await instance.textLinesForPageIndex(1);
      // textLines.forEach((textLine, textLineIndex) => {
      //   console.log(`Content for text line ${textLineIndex}`);
      //   console.log(`Text: ${textLine.contents}`);
      //   console.log(`Id: ${textLine.id}`);
      //   console.log(`Page index: ${textLine.pageIndex}`);
      //   console.log(
      //     `Bounding box: ${JSON.stringify(textLine.boundingBox.toJS())}`
      //   );
      // });
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [document]);

  // This div element will render the document to the DOM.
  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100vh",
        zIndex: "1001",
        position: "fixed",
      }}
    />
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DataType } from "types";

export type DashboardState = {
  isPremium: boolean;
  foldersData: Record<string, DataType[]>;
  sidebarWidth: number;
  currentFolder: string;
  destinationFolder: string;
  isFolderMoving: boolean;
  movingProgress: number;
  rootFolder: DataType;
  document: string;
};

const initialState: DashboardState = {
  isPremium: false,
  isFolderMoving: false,
  sidebarWidth: 240,
  movingProgress: 0,
  document: "",
  currentFolder: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
  destinationFolder: "",
  rootFolder: {
    id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
    createdAt: "2023-09-25T15:53:04.500Z",
    name: "My docs",
    type: "FOLDER",
  },
  foldersData: {
    "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00": [
      {
        id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed01",
        createdAt: "2023-09-25T15:53:04.500Z",
        modifiedAt: "2023-10-26T11:55:20.941Z",
        name: "AmegoAmegoAmegoAmegoAmegoAmegoAmegoAmegoAmegoAmegoAmegoAmegoAmego",
        childrenCount: 0,
        parentsCount: 1,
        type: "FOLDER",
        parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
      },
      {
        id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed02",
        createdAt: "2023-09-25T15:53:04.500Z",
        modifiedAt: "2023-10-26T11:55:20.941Z",
        name: "Alibaba",
        childrenCount: 2,
        parentsCount: 1,
        type: "FOLDER",
        parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
        childFolders: [
          {
            id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed22",
            createdAt: "2023-09-25T15:53:04.500Z",
            modifiedAt: "2023-10-26T11:55:20.941Z",
            name: "Alibaba inner 1",
            childrenCount: 0,
            parentsCount: 2,
            type: "FOLDER",
            parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed02",
          },
          {
            id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed33",
            createdAt: "2023-09-25T15:53:04.500Z",
            modifiedAt: "2023-10-26T11:55:20.941Z",
            name: "Alibaba inner 2",
            childrenCount: 0,
            parentsCount: 2,
            type: "FOLDER",
            parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed02",
          },
        ],
      },
      {
        id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed03",
        createdAt: "2023-09-25T15:53:04.500Z",
        modifiedAt: "2023-10-26T11:55:20.941Z",
        name: "Info from RDG",
        childrenCount: 0,
        parentsCount: 1,
        type: "FOLDER",
        parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
      },
      {
        id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed04",
        createdAt: "2023-09-25T15:53:04.500Z",
        modifiedAt: "2023-10-26T11:55:20.941Z",
        name: "Some pdf original file",
        childrenCount: 0,
        parentsCount: 1,
        type: "PDF",
        parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
      },
      {
        id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed05",
        createdAt: "2023-09-25T15:53:04.500Z",
        modifiedAt: "2023-10-26T11:55:20.941Z",
        name: "Some pdf certified file",
        childrenCount: 0,
        parentsCount: 1,
        type: "PDF_CERTIFIED",
        parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
      },
    ],
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setPremium(state, { payload }: PayloadAction<boolean>) {
      state.isPremium = payload;
    },
    setFoldersData(
      state,
      { payload }: PayloadAction<Record<string, DataType[]>>,
    ) {
      state.foldersData = payload;
    },
    setSidebarWidth(state, { payload }: PayloadAction<number>) {
      state.sidebarWidth = payload;
    },
    setCurrentFolder(state, { payload }: PayloadAction<string>) {
      state.currentFolder = payload;
    },
    setRootId(state, { payload }: PayloadAction<DataType>) {
      state.rootFolder = payload;
    },
    setDestinationFolder(state, { payload }: PayloadAction<string>) {
      state.destinationFolder = payload;
    },
    setIsFolderMoving(state, { payload }: PayloadAction<boolean>) {
      state.isFolderMoving = payload;
    },
    setMovingProgress(state, { payload }: PayloadAction<number>) {
      state.movingProgress = payload;
    },
    setDocument(state, { payload }: PayloadAction<string>) {
      state.document = payload;
    },
  },
});

export const {
  setPremium,
  setFoldersData,
  setSidebarWidth,
  setCurrentFolder,
  setRootId,
  setDestinationFolder,
  setIsFolderMoving,
  setMovingProgress,
  setDocument,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

import { FC } from "react";

import { SelectedType } from "types";

import searchBig from "assets/img/icons/search-big.svg";

import girlChating from "assets/img/girl-chatting-with-friends.png";
import femaleCandidate from "assets/img/female-candidate-job-profile.png";

import styles from "./styles.module.scss";

type NoTableDataProps = {
  isSearch?: boolean;
  isNoFilteredData?: boolean;
  isNoData?: boolean;
  selectedTypes: SelectedType[];
};

export const NoTableData: FC<NoTableDataProps> = ({
  isSearch,
  isNoFilteredData,
  isNoData,
  selectedTypes,
}) => {
  if (isNoData) {
    return (
      <div className={styles.NoTableData}>
        <img
          src={girlChating}
          alt="girlChating"
          width={450}
          height={280}
          style={{ opacity: 0.7 }}
        />
        No docs added yet...
      </div>
    );
  }

  if (isSearch && isNoFilteredData) {
    return (
      <div className={styles.NoTableData}>
        <img src={searchBig} alt="search" width={65} height={65} />
        No results...
      </div>
    );
  }

  if (
    isNoFilteredData &&
    selectedTypes.filter((el) => ["FOLDER", "PDF"].includes(el)).length > 0
  ) {
    return (
      <div className={styles.NoTableData}>
        <img
          src={girlChating}
          alt="girlChating"
          width={450}
          height={280}
          style={{ opacity: 0.7 }}
        />
        No {selectedTypes.includes("FOLDER") ? "folders" : "original files"}{" "}
        yet...
      </div>
    );
  }

  if (isNoFilteredData && selectedTypes.includes("PDF_CERTIFIED")) {
    return (
      <div className={styles.NoTableData}>
        <img
          src={femaleCandidate}
          alt="femaleCandidate"
          width={450}
          height={325}
          style={{ opacity: 0.7 }}
        />
        No certified files yet...
      </div>
    );
  }

  return <></>;
};

import { FC, useState, ChangeEvent } from "react";

import { Button, Checkbox } from "components/UI";

import styles from "./styles.module.scss";

type DeleteReasonModalProps = {
  onClose: () => void;
  onSubmit: () => void;
};

export const DeleteReasonModal: FC<DeleteReasonModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [details, setDetails] = useState<string>("");

  const reasons = [
    "Feeling unsafe",
    "Product issues",
    "Bad UX",
    "Bad scanning process",
    "Other",
  ];

  const handleSelectReason = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedReasons((prevState) =>
      prevState.includes(name)
        ? [...prevState.filter((el) => el !== name)]
        : [...prevState, name],
    );
  };

  const handleInputDetails = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setDetails(value);
  };

  const handleSendData = () => {
    console.log("selectedReasons:", selectedReasons);
    console.log("details:", details);
    onSubmit();
  };

  return (
    <div className={styles.DeleteReasonModal}>
      <h2 className={styles.title}>Please let’s us the reason of deletion</h2>
      <ul className={styles.reasons}>
        {reasons.map((el, index) => (
          <li key={index} className={styles.reason}>
            <Checkbox
              title={el}
              name={el}
              onChange={handleSelectReason}
              isChecked={selectedReasons.includes(el)}
              isWhite
            />
          </li>
        ))}
      </ul>
      <textarea
        className={styles.details}
        name="reason_details"
        cols={30}
        rows={10}
        onChange={handleInputDetails}
        value={details}
        maxLength={150}
        placeholder="Let us know details..."
      />
      <Button
        title="Send"
        variant="outlined"
        onClick={handleSendData}
        isDisabled={selectedReasons.length === 0}
      />
      <Button title="Not now" variant="textWhite" onClick={onClose} />
    </div>
  );
};

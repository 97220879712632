import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const CloseInCircleIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.64199 12.3582L12.3587 7.6415M12.3587 12.3582L7.64199 7.6415M10.0003 18.3332C14.5837 18.3332 18.3337 14.5832 18.3337 9.99984C18.3337 5.4165 14.5837 1.6665 10.0003 1.6665C5.41699 1.6665 1.66699 5.4165 1.66699 9.99984C1.66699 14.5832 5.41699 18.3332 10.0003 18.3332Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseInCircleIcon;

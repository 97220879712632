import { FC, useState, ChangeEvent } from "react";

import { useAppSelector, dashboard, useAppDispatch } from "store";
import { setFoldersData } from "store/dashboard";

import { Icon, Input, Button, Spinner } from "components/UI";
import { palette, sleep, toastSuccess } from "utils";

import styles from "./styles.module.scss";

type CreateFolderModalProps = {
  onClose: () => void;
};

export const CreateFolderModal: FC<CreateFolderModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { foldersData, currentFolder } = useAppSelector(dashboard);
  const [name, setName] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setName(e.target.value);
  };

  const handleCreateFolder = () => {
    dispatch(
      setFoldersData({
        [currentFolder]: [
          ...foldersData[currentFolder],
          {
            id: `${name} ${new Date().toISOString()}`,
            name,
            type: "FOLDER",
            childrenCount: 0,
            createdAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString(),
          },
        ],
      }),
    );
    onClose();
  };

  const handleSubmit = async () => {
    if (name === "My docs") {
      setIsError(true);
    } else {
      setIsLoading(true);
      await sleep(2000);
      handleCreateFolder();
      setIsLoading(false);
      toastSuccess("Folder was successfully created");
    }
  };

  const handleClear = () => {
    setName("");
    setIsError(false);
  };

  return (
    <div className={styles.CreateFolderModal}>
      <Icon name="close" className={styles.close} action={onClose} />
      <h2 className={styles.title}>Create folder in My docs</h2>
      <p className={styles.info}>
        Folder name must be unique within creation area
      </p>
      <Input
        className={styles.input}
        onChange={handleChangeName}
        value={name}
        error={isError && "The file name already exists, please re-enter"}
        name="name"
        isClearButton
        onClear={handleClear}
      />
      <Button
        variant="primary"
        title={isLoading ? <Spinner color={palette.white} /> : "Save"}
        onClick={handleSubmit}
        isDisabled={!name}
      />
    </div>
  );
};

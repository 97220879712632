import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const EyeOpenedIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9833 9.99993C12.9833 11.6499 11.6499 12.9833 9.99993 12.9833C8.34993 12.9833 7.0166 11.6499 7.0166 9.99993C7.0166 8.34993 8.34993 7.0166 9.99993 7.0166C11.6499 7.0166 12.9833 8.34993 12.9833 9.99993Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99987 16.8918C12.9415 16.8918 15.6832 15.1584 17.5915 12.1584C18.3415 10.9834 18.3415 9.00843 17.5915 7.83343C15.6832 4.83343 12.9415 3.1001 9.99987 3.1001C7.0582 3.1001 4.31654 4.83343 2.4082 7.83343C1.6582 9.00843 1.6582 10.9834 2.4082 12.1584C4.31654 15.1584 7.0582 16.8918 9.99987 16.8918Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeOpenedIcon;

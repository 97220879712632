// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/ClashDisplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/fonts/ClashDisplay-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/fonts/Urbanist-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/fonts/Urbanist-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Clash";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Clash";
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Urbanist";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Urbanist";
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
.styles_RowActions__uKAAm {
  display: flex;
  align-items: center;
  gap: 15px;
}
.styles_RowActions__uKAAm .styles_action__0AXSg {
  cursor: pointer;
}
.styles_RowActions__uKAAm .styles_action__0AXSg:hover {
  opacity: 0.7;
}
.styles_RowActions__uKAAm .styles_rowMenu__vcHYB {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/scss/_fonts.scss","webpack://./src/components/RowActions/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,gBAAA;EACA,+DAAA;ACCF;ADEA;EACE,oBAAA;EACA,gBAAA;EACA,+DAAA;ACAF;ADGA;EACE,uBAAA;EACA,gBAAA;EACA,+DAAA;ACDF;ADIA;EACE,uBAAA;EACA,gBAAA;EACA,+DAAA;ACFF;AAjBA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAmBF;AAjBE;EACE,eAAA;AAmBJ;AAjBI;EACE,YAAA;AAmBN;AAfE;EACE,kBAAA;AAiBJ","sourcesContent":["@font-face {\n  font-family: \"Clash\";\n  font-weight: 400;\n  src: url(\"../assets/fonts/ClashDisplay-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Clash\";\n  font-weight: 500;\n  src: url(\"../assets/fonts/ClashDisplay-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Urbanist\";\n  font-weight: 400;\n  src: url(\"../assets/fonts/Urbanist-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Urbanist\";\n  font-weight: 500;\n  src: url(\"../assets/fonts/Urbanist-Medium.ttf\") format(\"truetype\");\n}\n","@import \"scss/resources\";\n\n.RowActions {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n\n  & .action {\n    cursor: pointer;\n\n    &:hover {\n      opacity: 0.7;\n    }\n  }\n\n  & .rowMenu {\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RowActions": `styles_RowActions__uKAAm`,
	"action": `styles_action__0AXSg`,
	"rowMenu": `styles_rowMenu__vcHYB`
};
export default ___CSS_LOADER_EXPORT___;

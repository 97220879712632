export const palette = {
  white: "#fefefe",
  black: "#121212",

  red: "#f1403e",
  red20: "#fff4f4",

  peach: "#f87238",
  peach20: "#fff6ed",

  green: "#47a80c",
  green20: "#f4feed",
  actionGreen: "#7df644",

  blue: "#2986dc",
  blue20: "#f3f8ff",

  grey10: "#f8f8f8",
  grey20: "#f5f5f5",
  grey30: "#f0f0f0",
  grey40: "#d9d9d9",
  grey50: "#b9b9b9",
  grey70: "#989898",
  grey80: "#706f6f",
  grey90: "#484848",
};

import { FC } from "react";

import { Icon } from "components/UI";
import { palette } from "utils";

import styles from "./styles.module.scss";

export const SubscriptionStatusModal: FC = () => {
  return (
    <div className={styles.SubscriptionStatusModal}>
      <header className={styles.header}>
        <h2 className={styles.text}>
          Status: <span className={styles.active}>Active</span>
        </h2>
        <p className={styles.text}>Yearly subscription until 23 Apr 2024</p>
        <p className={styles.info}>$100,00 per year</p>
      </header>

      <main className={styles.main}>
        <h2 className={styles.text}>You have access to:</h2>

        <ul className={styles.privileges}>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            Certified docs
          </li>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            No watermarks
          </li>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            15GB for devices synс
          </li>
        </ul>
      </main>
    </div>
  );
};

import { FC, HTMLAttributes, ReactElement, useRef } from "react";
import Transition, {
  TransitionStatus,
} from "react-transition-group/Transition";
import OutsideClickHandler from "react-outside-click-handler";

import { opacityTransitionConfig, cs } from "utils";

import styles from "./styles.module.scss";

interface ModalProps extends HTMLAttributes<HTMLElement> {
  isShowed: boolean;
  onClose?: () => void;
  className?: string;
  noDarkness?: boolean;
}

export const Modal: FC<ModalProps> = ({
  children,
  isShowed,
  onClose = () => {},
  className = "",
  noDarkness,
}): ReactElement => {
  const nodeRef = useRef(null);

  return (
    <Transition
      in={isShowed}
      appear
      mountOnEnter
      unmountOnExit
      timeout={opacityTransitionConfig().timeout}
      nodeRef={nodeRef}
    >
      {(state: TransitionStatus) => (
        <section
          ref={nodeRef}
          className={cs([styles.Modal, noDarkness && styles.noDarkness])}
          style={{
            ...opacityTransitionConfig().defaultStyles,
            ...opacityTransitionConfig().transitionStyles[state],
          }}
        >
          <OutsideClickHandler onOutsideClick={onClose}>
            <div className={cs([styles.container, className])}>{children}</div>
          </OutsideClickHandler>
        </section>
      )}
    </Transition>
  );
};

import { useState } from "react";

import { Icon, Modal } from "components/UI";
import {
  ConfirmPasswordModal,
  AreYouSureModal,
  DeleteReasonModal,
} from "components";

import { cs } from "utils";
import { useAuth } from "hooks";

import styles from "./styles.module.scss";

export const LogoutDelete = () => {
  const { handleLogout } = useAuth();
  const [isConfirmPasswordModal, setIsConfirmPasswordModal] =
    useState<boolean>(false);
  const [isLogOutModal, setIsLogOutModal] = useState<boolean>(false);
  const [isDeleteAcModal, setIsDeleteAcModal] = useState<boolean>(false);
  const [isDeleteReasonModal, setIsDeleteReasonModal] =
    useState<boolean>(false);

  const openEditAccountModal = () => {
    setIsConfirmPasswordModal(true);
  };

  const handleClosePasswordModal = () => {
    setIsConfirmPasswordModal(false);
  };

  const handleDeleteAccount = () => {
    console.log("account deleted");
    handleCloseDeleteReasonModal();
  };

  const handleOpenLogOutModal = () => {
    setIsLogOutModal(true);
  };

  const handleCloseLogOutModal = () => {
    setIsLogOutModal(false);
  };

  const handleOpenDeleteAcModal = () => {
    setIsDeleteAcModal(true);
  };

  const handleCloseDeleteAcModal = () => {
    setIsDeleteAcModal(false);
  };

  const changeAccount = () => {
    setIsConfirmPasswordModal(false);
    handleOpenDeleteAcModal();
  };

  const handleOpenDeleteReasonModal = () => {
    setIsDeleteReasonModal(true);
  };

  const handleCloseDeleteReasonModal = () => {
    setIsDeleteReasonModal(false);
  };

  return (
    <>
      <Modal
        isShowed={isConfirmPasswordModal}
        onClose={handleClosePasswordModal}
      >
        <ConfirmPasswordModal
          onClose={handleClosePasswordModal}
          onSubmit={changeAccount}
        />
      </Modal>
      <Modal isShowed={isLogOutModal} onClose={handleCloseLogOutModal}>
        <AreYouSureModal
          onClose={handleCloseLogOutModal}
          onSubmit={handleLogout}
          info="You will be logged out of your account."
          confirmButtonTitle="Log out"
        />
      </Modal>
      <Modal isShowed={isDeleteAcModal} onClose={handleCloseDeleteAcModal}>
        <AreYouSureModal
          onClose={handleCloseDeleteAcModal}
          onSubmit={handleOpenDeleteReasonModal}
          info="You will lose all data from account"
          confirmButtonTitle="Delete account"
        />
      </Modal>
      <Modal
        isShowed={isDeleteReasonModal}
        onClose={handleCloseDeleteReasonModal}
      >
        <DeleteReasonModal
          onClose={handleCloseDeleteReasonModal}
          onSubmit={handleDeleteAccount}
        />
      </Modal>
      <div className={cs([styles.LogoutDelete, styles.item])}>
        <div className={styles.menuItem} onClick={handleOpenLogOutModal}>
          <Icon name="login" size={18} /> Log out
        </div>
        <div className={styles.menuItem} onClick={openEditAccountModal}>
          <Icon name="trash" size={18} /> Delete account
        </div>
      </div>
    </>
  );
};

import { useState, useEffect } from "react";
import { useDroppable } from "@dnd-kit/core";
import { NavLink } from "react-router-dom";

import { useAppDispatch, useAppSelector, dashboard, auth } from "store";
import { setPremium, setCurrentFolder } from "store/dashboard";

import { PATHES } from "constants/pathes";
import { Icon, Modal } from "components/UI";
import {
  SidebarFolder,
  TryPro,
  PremiumModal,
  TrialActivatedModal,
  CloudMemory,
  SidebarFolders,
} from "components";

import mainLogo from "assets/img/main-logo.png";
import { useResize } from "./useResize";

import styles from "./styles.module.scss";

export const Sidebar = () => {
  const dispatch = useAppDispatch();
  const { isPremium, sidebarWidth, foldersData, rootFolder } =
    useAppSelector(dashboard);
  const { editablePassword, editableEmail } = useAppSelector(auth);
  const { node, isOver, setNodeRef } = useDroppable({
    id: "droppable",
  });
  const { sidebarRef, startResizing } = useResize();

  const [openedFolders, setOpenedFolders] = useState<string[]>([]);
  const [isPremiumModal, setPremiumModal] = useState<boolean>(
    !isPremium && !editablePassword && !editableEmail,
  );
  const [isTrialActivatedModal, setTrialActivatedModal] =
    useState<boolean>(false);
  const [isOverRoot, setOverRoot] = useState<boolean>(false);
  const [isTip, setIsTip] = useState<boolean>(true);

  useEffect(() => {
    if (isOver) {
      setOverRoot(true);
    } else {
      setOverRoot(false);
    }
  }, [isOver]);

  const handleOpenFolder = (folder: string) => {
    setIsTip(false);
    setOpenedFolders((prevState) =>
      prevState.includes(folder)
        ? [...prevState.filter((el) => el !== folder)]
        : [...prevState, folder],
    );
  };

  const handleSubscribe = () => {
    dispatch(setPremium(true));
    setTrialActivatedModal(false);
  };

  const handleOpenTryPremium = () => {
    setPremiumModal(true);
  };

  const handleClearCurrent = () => {
    dispatch(setCurrentFolder(""));
  };

  return (
    <nav
      className={styles.Sidebar}
      ref={sidebarRef}
      style={{ width: sidebarWidth }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <Modal isShowed={isPremiumModal}>
        <PremiumModal
          onClose={() => setPremiumModal(false)}
          onSubmit={() => setTrialActivatedModal(true)}
        />
      </Modal>
      <Modal isShowed={isTrialActivatedModal}>
        <TrialActivatedModal onClose={handleSubscribe} />
      </Modal>
      <div className={styles.resizer} onMouseDown={startResizing} />
      <main className={styles.main}>
        <NavLink to={PATHES.HOME} className={styles.mainLogo}>
          <img src={mainLogo} alt="main-logo" width="91" height="24" />
        </NavLink>
        <ul className={styles.list}>
          <li className={styles.navItem} ref={setNodeRef}>
            <SidebarFolder
              title={rootFolder.name}
              id={rootFolder.id}
              isOpened={openedFolders.includes(rootFolder.id)}
              handleOpenFolder={() => handleOpenFolder(rootFolder.id)}
              childrenCount={Object.keys(foldersData).length}
              isTip={isTip}
              setIsTip={setIsTip}
              isRoot
            />
            <SidebarFolders
              handleOpenFolder={handleOpenFolder}
              openedFolders={openedFolders}
              isOverRoot={isOverRoot}
              rootName={node?.current?.outerText || ""}
            />
          </li>
          <li className={styles.navItem} onClick={handleClearCurrent}>
            <NavLink
              to={PATHES.TRASH}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              <Icon name="trash" />
              Trash bin
            </NavLink>
          </li>
          <li className={styles.navItem} onClick={handleClearCurrent}>
            <NavLink
              to={PATHES.HELP}
              className={({ isActive }) =>
                [styles.link, isActive ? styles.active : ""].join(" ")
              }
            >
              <Icon name="info" />
              Help
            </NavLink>
          </li>
        </ul>
        {!isPremium && <TryPro onClick={handleOpenTryPremium} />}
      </main>
      <footer className={styles.footer}>
        <CloudMemory />
        <div className={styles.rights}>All rights reserved | 2024</div>
      </footer>
    </nav>
  );
};

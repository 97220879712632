import { FC } from "react";
import { Tooltip } from "react-tooltip";

import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const DownloadIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace = "bottom",
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <>
      <svg
        className={className}
        onClick={action}
        width={width}
        height={height}
        data-tooltip-id={dataTooltipId}
        data-tooltip-content={dataTooltipContent}
        data-tooltip-place={dataTooltipPlace}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.5 11.5C18.5 11.5 18.5 13.0426 18.5 12.9292V13.0426C18.5 16.9395 16.9009 18.5 12.9078 18.5H7.09222C3.09905 18.5 1.5 16.9395 1.5 13.0426V11.5M10.0045 1.5V12.7287M12.9971 10.7846L10.0045 13.7051L7.01182 10.7846"
          stroke={color || palette.black}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {dataTooltipId && (
        <Tooltip
          id={dataTooltipId}
          positionStrategy="fixed"
          delayShow={300}
          noArrow
        />
      )}
    </>
  );
};

export default DownloadIcon;

import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const EditIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0551 1.7109H7.37619C3.17891 1.7109 1.5 3.38981 1.5 7.58708V12.6238C1.5 16.8211 3.17891 18.5 7.37619 18.5H12.4129C16.6102 18.5 18.2891 16.8211 18.2891 12.6238V10.9449M12.3374 3.51572C12.8998 5.52202 14.4696 7.0918 16.4843 7.66263M13.286 2.56714L6.67104 9.18205C6.41921 9.43388 6.16737 9.92916 6.117 10.2901L5.75604 12.8169C5.62173 13.7319 6.26811 14.3699 7.18311 14.244L9.70987 13.883C10.0624 13.8326 10.5577 13.5808 10.818 13.329L17.4329 6.71405C18.5745 5.57239 19.1118 4.24605 17.4329 2.56714C15.7539 0.88823 14.4276 1.42548 13.286 2.56714Z"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;

import { FC } from "react";

import { palette, cs } from "utils";

import styles from "./styles.module.scss";

type SpinnerProps = {
  size?: number;
  color?: string;
  className?: string;
};

export const Spinner: FC<SpinnerProps> = ({
  size = 20,
  color = palette.black,
  className = "",
}) => {
  return (
    <div
      className={cs([styles.Spinner, className])}
      style={{ width: `${size} px`, color }}
    />
  );
};

import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const TrashIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 4.98317C14.725 4.70817 11.9333 4.5665 9.15 4.5665C7.5 4.5665 5.85 4.64984 4.2 4.8165L2.5 4.98317M7.08333 4.1415L7.26667 3.04984C7.4 2.25817 7.5 1.6665 8.90833 1.6665H11.0917C12.5 1.6665 12.6083 2.2915 12.7333 3.05817L12.9167 4.1415M15.7083 7.6165L15.1667 16.0082C15.075 17.3165 15 18.3332 12.675 18.3332H7.325C5 18.3332 4.925 17.3165 4.83333 16.0082L4.29167 7.6165M8.60833 13.7498H11.3833M7.91667 10.4165H12.0833"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;

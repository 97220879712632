import { FC } from "react";
import { BrowserRouter } from "react-router-dom";

// import { setAuthenticated } from "store/auth";
import { useAppSelector, auth } from "store";

import { Private } from "./Private";
import { Public } from "./Public";

const Router: FC = () => {
  // const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(auth);

  // const handleChangeAuth = () => {
  //   dispatch(setAuthenticated(!isAuthenticated));
  // };

  return (
    <BrowserRouter>
      {/* <div onClick={handleChangeAuth} style={{ position: "fixed", right: "0" }}>
        Change Auth
      </div> */}
      {isAuthenticated ? <Private /> : <Public />}
    </BrowserRouter>
  );
};

export default Router;

import { FC } from "react";

import { Icon } from "components/UI";
import { palette, cs } from "utils";

import styles from "./styles.module.scss";

export const CloudMemory: FC = () => {
  const MEMORY_FILLED = 100; // MB
  const memoryFilledPart = (MEMORY_FILLED / 200) * 100;
  const isHighFreeSpace = memoryFilledPart < 50;
  const isLowFreeSpace = memoryFilledPart > 90;
  return (
    <div className={styles.CloudMemory}>
      <div className={styles.progressBar}>
        <div
          className={cs([
            styles.value,
            isHighFreeSpace && styles.success,
            isLowFreeSpace && styles.error,
          ])}
          style={{ width: `${memoryFilledPart}%` }}
        />
      </div>
      <p className={styles.info}>
        <Icon name="clouds" color={palette.white} />
        {MEMORY_FILLED}MB/200MB for sync
      </p>
      {!isHighFreeSpace && (
        <div className={styles.increaseButton}>Increase cloud to 15GB</div>
      )}
    </div>
  );
};

import { useAppDispatch } from "store";
import { setAuthenticated } from "store/auth";
import { Auth, removeSession, getSession } from "api";

export const useAuth = () => {
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    const { refreshToken } = getSession();
    await Auth.logOut({ refreshToken });
    dispatch(setAuthenticated(false));
    removeSession();
  };

  return {
    handleLogout,
  };
};

import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const ChevronRightIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6775 9.26092C15.1075 9.66442 15.1075 10.3356 14.6775 10.7391L7.78088 17.2114C7.11275 17.8384 6 17.3766 6 16.4723V3.52773C6 2.62342 7.11275 2.16162 7.78088 2.78864L14.6775 9.26092Z"
        fill={color || palette.black}
      />
    </svg>
  );
};

export default ChevronRightIcon;

import { FC, Fragment, useState } from "react";

// import { useAppSelector, dashboard } from "store";

import { Icon } from "components/UI";
import { palette, cs } from "utils";

import styles from "./styles.module.scss";

type BreadcrumbsProps = {
  className?: string;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ className }) => {
  // const { currentFolder: currentFolderId } = useAppSelector(dashboard);
  const [currentFolder, setCurrentFolder] = useState<any>({
    id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed55",
    createdAt: "2023-09-25T15:53:04.500Z",
    modifiedAt: "2023-10-26T11:55:20.941Z",
    name: "Alibaba Inner 5",
    childrenCount: 2,
    parentsCount: 1,
    type: "FOLDER",
    parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed42",
  });
  const [parents, setParents] = useState([
    {
      id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
      createdAt: "2023-09-25T15:53:04.500Z",
      modifiedAt: "2023-10-26T11:55:20.941Z",
      name: "My docs",
      childrenCount: 2,
      parentsCount: 1,
      type: "FOLDER",
      parentFolderId: null,
    },
    {
      id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed02",
      createdAt: "2023-09-25T15:53:04.500Z",
      modifiedAt: "2023-10-26T11:55:20.941Z",
      name: "Alibaba",
      childrenCount: 2,
      parentsCount: 1,
      type: "FOLDER",
      parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed00",
    },
    {
      id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed10",
      createdAt: "2023-09-25T15:53:04.500Z",
      modifiedAt: "2023-10-26T11:55:20.941Z",
      name: "Alibaba Inner 1",
      childrenCount: 2,
      parentsCount: 1,
      type: "FOLDER",
      parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed02",
    },
    {
      id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed22",
      createdAt: "2023-09-25T15:53:04.500Z",
      modifiedAt: "2023-10-26T11:55:20.941Z",
      name: "Alibaba Inner 2",
      childrenCount: 2,
      parentsCount: 1,
      type: "FOLDER",
      parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed10",
    },
    {
      id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed30",
      createdAt: "2023-09-25T15:53:04.500Z",
      modifiedAt: "2023-10-26T11:55:20.941Z",
      name: "Alibaba Inner 3",
      childrenCount: 2,
      parentsCount: 1,
      type: "FOLDER",
      parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed22",
    },
    {
      id: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed42",
      createdAt: "2023-09-25T15:53:04.500Z",
      modifiedAt: "2023-10-26T11:55:20.941Z",
      name: "Alibaba Inner 4",
      childrenCount: 2,
      parentsCount: 1,
      type: "FOLDER",
      parentFolderId: "a1fe0e75-2ea0-41a0-933e-cc8bdabbed30",
    },
  ]); // getParentsArr

  const renderArrow = () => (
    <Icon
      name="chevron-left-empty"
      className={styles.arrow}
      color={palette.grey70}
      size={16}
    />
  );

  const goUp = () => {
    setCurrentFolder(parents[parents.length - 2]);
    setParents((prevState) => prevState.slice(0, prevState.length - 2));
  };

  return (
    <nav className={cs([styles.Breadcrumbs, className])}>
      {parents.length > 3 ? (
        <>
          <>
            <div className={styles.link}>{parents[0].name}</div>
            {renderArrow()}
          </>
          <>
            <div className={styles.link} onClick={goUp}>
              ...
            </div>
            {renderArrow()}
          </>
          {parents.slice(parents.length - 1).map((el, index) => (
            <Fragment key={index}>
              <div className={styles.link}>{el.name}</div>
              {renderArrow()}
            </Fragment>
          ))}
        </>
      ) : (
        parents.map((el, index) => (
          <Fragment key={index}>
            <div className={styles.link}>{el.name}</div>
            {renderArrow()}
          </Fragment>
        ))
      )}
      <div className={cs([styles.link, styles.active])}>
        {currentFolder.name}
      </div>
    </nav>
  );
};

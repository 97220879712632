import { FC } from "react";
import { Tooltip } from "react-tooltip";

import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const FileIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace = "bottom",
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <>
      <svg
        className={className}
        onClick={action}
        width={width}
        height={height}
        data-tooltip-id={dataTooltipId}
        data-tooltip-content={dataTooltipContent}
        data-tooltip-place={dataTooltipPlace}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3337 8.33317V12.4998C18.3337 16.6665 16.667 18.3332 12.5003 18.3332H7.50033C3.33366 18.3332 1.66699 16.6665 1.66699 12.4998V7.49984C1.66699 3.33317 3.33366 1.6665 7.50033 1.6665H11.667M18.3337 8.33317H15.0003C12.5003 8.33317 11.667 7.49984 11.667 4.99984V1.6665M18.3337 8.33317L11.667 1.6665M5.83366 10.8332H10.8337M5.83366 14.1665H9.16699"
          stroke={color || palette.black}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {dataTooltipId && (
        <Tooltip
          id={dataTooltipId}
          delayShow={300}
          positionStrategy="fixed"
          noArrow
        />
      )}
    </>
  );
};

export default FileIcon;

import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const SignIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.90837 4.20835C10.2667 6.50835 12.1334 8.26668 14.45 8.50002M2.5 19.5H5.5L9.32859 19.0214C9.4425 19.0072 9.55446 18.9802 9.66235 18.941L13.1834 17.6606C13.6841 17.4785 14.1036 18.0757 13.7625 18.485V18.485C13.4197 18.8964 13.8451 19.4956 14.3466 19.3075L15.7305 18.7885C16.1679 18.6245 16.6586 18.8172 16.8675 19.235L17 19.5M11.05 3.00002L4.20836 10.2417C3.95003 10.5167 3.70003 11.0584 3.65003 11.4334L3.3417 14.1334C3.23336 15.1084 3.93336 15.775 4.90003 15.6084L7.58337 15.15C7.95837 15.0834 8.48336 14.8084 8.7417 14.525L15.5834 7.28335C16.7667 6.03335 17.3 4.60835 15.4584 2.86668C13.625 1.14168 12.2334 1.75002 11.05 3.00002Z"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignIcon;

import { FC } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink } from "react-router-dom";

import { PATHES } from "constants/pathes";

import { Icon } from "components/UI";
import { palette, cs } from "utils";
import { useAuth } from "hooks";

import styles from "./styles.module.scss";

type ProfileActionsProps = {
  onClose?: () => void;
};

export const ProfileActions: FC<ProfileActionsProps> = ({
  onClose = () => {},
}) => {
  const { handleLogout } = useAuth();

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div className={styles.ProfileActions}>
        <ul className={styles.menuList}>
          <li>
            <NavLink to={PATHES.SETTINGS} className={styles.menuItem}>
              <Icon name="settings" size={18} color={palette.black} />
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink to={PATHES.MY_SIGNATURES} className={styles.menuItem}>
              <Icon name="sign" size={18} color={palette.black} />
              My signatures
            </NavLink>
          </li>
          <li>
            <NavLink to={PATHES.SUBSCRIPTION} className={styles.menuItem}>
              <Icon name="check-in-flower" size={18} color={palette.black} />
              Subscription
            </NavLink>
          </li>
          <li
            className={cs([styles.menuItem, styles.topBorder])}
            onClick={handleLogout}
          >
            <Icon name="login" size={18} color={palette.black} />
            Log out
          </li>
        </ul>
      </div>
    </OutsideClickHandler>
  );
};

import { EmailPassword, LogoutDelete, ChangeLanguage } from "components";

import styles from "./styles.module.scss";

export const Settings = () => {
  return (
    <div className={styles.Settings}>
      <EmailPassword />
      <ChangeLanguage />
      <LogoutDelete />
    </div>
  );
};

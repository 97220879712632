import { FC } from "react";

// import { Icon } from "../Icon";
import arrowLeft from "assets/img/icons/arrow-left.svg";

import styles from "./styles.module.scss";

type BackButtonProps = {
  onClick: () => void;
};

export const BackButton: FC<BackButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.BackButton} onClick={onClick}>
      <img src={arrowLeft} alt="back" />
    </button>
  );
};

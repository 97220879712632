import { FC } from "react";
import styles from "./styles.module.scss";

type TryProProps = {
  onClick: () => void;
};

export const TryPro: FC<TryProProps> = ({ onClick }) => {
  return (
    <div className={styles.TryPro} onClick={onClick}>
      <h2 className={styles.title}>
        Try to PRO version for <span className={styles.active}>FREE</span>
      </h2>
      <p className={styles.text}>Learn more</p>
    </div>
  );
};

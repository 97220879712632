import { FC, HTMLAttributes, useState, MouseEvent } from "react";
import { NavLink } from "react-router-dom";

import { useAppDispatch, useAppSelector, dashboard } from "store";
import { setCurrentFolder, setDestinationFolder } from "store/dashboard";

import { SidebarTip } from "components";
import { PATHES } from "constants/pathes";
import { cs, palette } from "utils";

import { Icon } from "../UI/Icon";
import { ContextMenu } from "../ContextMenu";

import styles from "./styles.module.scss";

interface SidebarFolderProps extends HTMLAttributes<HTMLElement> {
  title: string;
  id: string;
  isOpened: boolean;
  isRoot?: boolean;
  handleOpenFolder: () => void;
  childrenCount?: number;
  setIsTip?: (value: boolean) => void;
  isTip?: boolean;
}

export const SidebarFolder: FC<SidebarFolderProps> = ({
  id,
  title,
  isOpened,
  isRoot,
  isTip,
  handleOpenFolder,
  children,
  childrenCount,
  setIsTip = () => {},
}) => {
  const dispatch = useAppDispatch();
  const { currentFolder } = useAppSelector(dashboard);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isContext, setIsContext] = useState<boolean>(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  const handleRightClick = (e: MouseEvent<HTMLElement>) => {
    if (e.type === "contextmenu") {
      e.preventDefault();
      setIsContext(true);
      setPoints({
        x: e.pageX,
        y: e.pageY,
      });
    }
  };

  const handleClick = () => {
    dispatch(setCurrentFolder(id));
  };

  const handleHover = () => {
    setIsHovered(true);
    dispatch(setDestinationFolder(title));
  };

  const handleOpenMenu = (e: MouseEvent<SVGElement>) => {
    setIsContext(true);
    setPoints({
      x: e.pageX - 120,
      y: e.pageY + 20,
    });
  };

  return (
    <div
      className={styles.SidebarFolder}
      onContextMenu={handleRightClick}
      onMouseEnter={handleHover}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!childrenCount ? (
        <div className={styles.chevron} />
      ) : (
        <Icon
          className={cs([
            styles.chevron,
            isOpened && styles.opened,
            isTip && styles.withTip,
          ])}
          name="chevron-right-filled"
          action={handleOpenFolder}
          size={12}
        />
      )}
      <NavLink
        onClick={handleClick}
        to={PATHES.HOME}
        className={({ isActive }) =>
          cs([
            styles.link,
            currentFolder === id && styles.active,
            isRoot && styles.root,
          ])
        }
      >
        <Icon
          className={styles.folderIcon}
          name={isRoot ? "docs-empty" : "folder-empty"}
        />
        {title}
      </NavLink>
      {(isHovered || isContext) && (
        <Icon
          name="three-dots-vertical"
          action={handleOpenMenu}
          color={palette.grey80}
          className={cs([styles.contextIcon, isContext && styles.active])}
        />
      )}
      {isTip && <SidebarTip onClose={() => setIsTip(false)} />}
      {isContext && (
        <ContextMenu
          onClose={() => setIsContext(false)}
          theme="dark"
          items={["add-folder", "rename", "move", "trash"]}
          top={points.y}
          left={points.x}
          isFixed
        />
      )}
      {children}
    </div>
  );
};

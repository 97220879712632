import { FC } from "react";
import { Tooltip } from "react-tooltip";

import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const ShareIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace = "bottom",
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <>
      <svg
        className={className}
        onClick={action}
        width={width}
        height={height}
        data-tooltip-id={dataTooltipId}
        data-tooltip-content={dataTooltipContent}
        data-tooltip-place={dataTooltipPlace}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9708 7.22631C17.1868 7.50368 18.5 9.15895 18.5 12.7826V12.8989C18.5 16.8984 16.9009 18.5 12.9078 18.5H7.09222C3.09905 18.5 1.5 16.8984 1.5 12.8989V12.7826C1.5 9.18579 2.79532 7.53053 5.9577 7.23526M10.0045 12.6842V2.50211M12.9971 4.49737L10.0045 1.5L7.01182 4.49737"
          stroke={color || palette.black}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {dataTooltipId && (
        <Tooltip
          id={dataTooltipId}
          delayShow={300}
          positionStrategy="fixed"
          noArrow
        />
      )}
    </>
  );
};

export default ShareIcon;

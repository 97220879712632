import { FC, useState, useEffect } from "react";

import { Icon, Button } from "components/UI";
import { File } from "types";

import styles from "./styles.module.scss";

type MovingModalProps = {
  onCancel: (file: File) => void;
  onSuccess: () => void;
};

export const MovingModal: FC<MovingModalProps> = ({ onCancel, onSuccess }) => {
  const [movingProgress, setMovingProgress] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMovingProgress((prevState) => prevState + 10);
    }, 1000);
    if (movingProgress > 99) {
      onSuccess();
      setMovingProgress(0);
    }
    return () => clearInterval(interval);
  }, [movingProgress, onSuccess]);

  return (
    <div className={styles.MovingModal}>
      <Icon name="move" size={32} className={styles.icon} />
      <h2 className={styles.title}>Moving files, please wait...</h2>
      <Button
        variant="textBlack"
        size="sm"
        onClick={onCancel}
        title="Cancel"
        className={styles.cancelButton}
      />
      <div className={styles.progressBar}>
        <div
          className={styles.success}
          style={{ width: `${movingProgress}%` }}
        />
      </div>
    </div>
  );
};

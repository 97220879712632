import { FC, useState } from "react";

import { Icon, Button, Spinner, CircleProgressBar } from "components/UI";
import { palette, cs } from "utils";
import { File } from "types";

import styles from "./styles.module.scss";

type UploadModalProps = {
  onClose: () => void;
  onCancel: (file: File) => void;
  onCancelAll: () => void;
  isUploading: boolean;
  files: File[];
};

export const UploadModal: FC<UploadModalProps> = ({
  onClose,
  onCancel,
  onCancelAll,
  isUploading,
  files,
}) => {
  const [isFullView, setIsFullView] = useState<boolean>(true);

  const handleToggleView = () => {
    setIsFullView((prevState) => !prevState);
  };

  return (
    <div className={styles.UploadModal}>
      <div className={styles.container}>
        <header className={cs([styles.header, isFullView && styles.fullView])}>
          {isUploading ? (
            <Spinner size={24} className={styles.statusIcon} />
          ) : (
            <Icon
              name="done-success"
              className={styles.statusIcon}
              color={palette.green}
            />
          )}
          <div className={styles.text}>
            <h2 className={cs([styles.title, !isUploading && styles.success])}>
              Uploading {isUploading ? "in progress" : "finished"}
            </h2>
            <p className={styles.info}>
              {files.length} items in total{" "}
              {files.filter((el) => el.status === "success").length} items
              uploaded
            </p>
          </div>
          <Icon
            name="chevron-down-empty"
            className={cs([styles.arrow, !isFullView && styles.up])}
            size={18}
            action={handleToggleView}
          />
          {isUploading ? (
            <Button
              onClick={onCancelAll}
              title="Cancel All"
              variant="textBlack"
              size="sm"
            />
          ) : (
            <Icon name="close" className={styles.close} action={onClose} />
          )}
        </header>
        <ul className={cs([styles.files, isFullView && styles.fullView])}>
          {files
            .sort((a, b) => (a.order > b.order ? -1 : 1))
            .map((el, index) => (
              <li key={index} className={styles.file}>
                <Icon name="file" />
                <p className={styles.documentTitle}>{el.title}</p>
                <div className={styles.directory}>to {el.dir}</div>
                <div
                  className={cs([
                    styles.status,
                    (!isUploading || el.status !== "canceled") &&
                      styles.success,
                  ])}
                >
                  {isUploading && el.status !== "canceled" && (
                    <>
                      <CircleProgressBar percentage={60} />
                      <Button
                        onClick={() => onCancel(el)}
                        title="Cancel"
                        variant="textBlack"
                        size="sm"
                      />
                    </>
                  )}
                  {!isUploading && el.status === "success" && (
                    <>
                      <Icon
                        name="done-success"
                        color={palette.green}
                        size={15}
                      />
                      <p className={styles.success}>Uploaded</p>
                    </>
                  )}
                  {!isUploading && el.status === "error" && (
                    <>
                      <Icon
                        name="warning-error"
                        color={palette.red}
                        size={15}
                      />
                      <p className={styles.error}>Upload failed</p>
                    </>
                  )}
                  {el.status === "canceled" && (
                    <>
                      <Icon
                        name="close-in-circle"
                        color={palette.red}
                        size={15}
                      />
                      <p className={styles.error}>Canceled</p>
                    </>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

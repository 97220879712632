import { FC } from "react";

import { Icon, Button } from "components/UI";

import styles from "./styles.module.scss";

type CancleUploadingModalProps = {
  onClose: () => void;
  onSubmit: () => void;
};

export const CancleUploadingModal: FC<CancleUploadingModalProps> = ({
  onClose,
  onSubmit = () => {},
}) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <div className={styles.CancleUploadingModal}>
      <Icon name="close" className={styles.close} action={onClose} />
      <h2 className={styles.title}>Cancel uploading</h2>
      <p className={styles.info}>
        There are files to be uploaded. Are you sure to cancel uploading?
      </p>
      <div className={styles.buttons}>
        <Button variant="primary" title="Confirm" onClick={handleSubmit} />
        <Button variant="secondary" title="Keep uploading" onClick={onClose} />
      </div>
    </div>
  );
};

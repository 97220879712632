import { useState } from "react";

import { Icon } from "components/UI";
import { ProfileActions } from "components";
import { palette } from "utils";

import styles from "./styles.module.scss";

export const AccountButton = () => {
  const [isProfileActions, setIsProfileActions] = useState<boolean>(false);

  return (
    <div
      className={styles.AccountButton}
      onClick={() => setIsProfileActions(true)}
    >
      <Icon name="user-filled" color={palette.black} />
      {isProfileActions && (
        <ProfileActions onClose={() => setIsProfileActions(false)} />
      )}
    </div>
  );
};

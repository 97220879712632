import {
  FC,
  useState,
  useEffect,
  ChangeEvent,
  ReactElement,
  useMemo,
} from "react";

import { useAppDispatch, useAppSelector, dashboard } from "store";
import { setDestinationFolder } from "store/dashboard";

import { FilterType, TableRow, NoTableData, Breadcrumbs } from "components";
import { Checkbox, Icon } from "components/UI";
import { cs, getMenuItems, menuTitle } from "utils";
import { SelectedType, DataType } from "types";

import arrowDown from "assets/img/icons/arrow-down.svg";

import styles from "./styles.module.scss";

type FieldSorted = "name" | "createdAt";

type CustomTableProps = {
  search?: string;
  renderDropzone: () => ReactElement;
  isDragActive: boolean;
};

export const CustomTable: FC<CustomTableProps> = ({
  search,
  renderDropzone,
  isDragActive,
}) => {
  const dispatch = useAppDispatch();
  const { foldersData, currentFolder } = useAppSelector(dashboard);
  const [tableData, setTableData] = useState<DataType[]>([]);
  const [sorting, setSorting] = useState({
    name: false,
    createdAt: false,
  });
  const [selectedItems, setSelectedItems] = useState<DataType[]>([]);
  const initialFilterTypes: SelectedType[] = ["FOLDER", "PDF_CERTIFIED", "PDF"];
  const [selectedTypes, setSelectedTypes] =
    useState<SelectedType[]>(initialFilterTypes);

  const initialData = useMemo(
    () => foldersData[currentFolder] || [],
    [currentFolder, foldersData],
  );

  useEffect(() => {
    setTableData(initialData);
  }, [initialData, currentFolder]);

  const handleSelectAll = () => {
    setSelectedItems((prevState) =>
      prevState.length < initialData?.length ? [...initialData] : [],
    );
  };

  const handleSelectItem = (item: DataType) => {
    setSelectedItems((prevState) =>
      prevState.some((el) => el.name === item.name)
        ? [...prevState.filter((el) => el.name !== item.name)]
        : [...prevState, item],
    );
  };

  const sortTable = (field: FieldSorted) => {
    setTableData(
      tableData.slice().sort((a: DataType, b: DataType) => {
        const asc = sorting[field] ? 1 : -1;
        const desc = sorting[field] ? -1 : 1;
        return a[field] < b[field] ? asc : desc;
      }),
    );
  };

  const handleSort = (field: FieldSorted) => {
    setSorting((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
    sortTable(field);
  };

  const handleFilter = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    const newType = name as SelectedType;
    setSelectedTypes((prevState) =>
      prevState.includes(newType)
        ? [...prevState.filter((el) => el !== newType)]
        : [...prevState, newType],
    );
  };

  const selectAllTypes = () => {
    setSelectedTypes(
      selectedTypes.length < initialFilterTypes.length
        ? initialFilterTypes
        : [],
    );
  };

  const filteredData = tableData
    ?.filter((el) =>
      search
        ? el.name
            .toLocaleLowerCase()
            .startsWith(search.toLocaleLowerCase().trim())
        : true,
    )
    .filter(
      (el) => selectedTypes.length === 0 || selectedTypes.includes(el.type),
    )
    .sort((a: DataType, b: DataType) => {
      return a.type === "FOLDER" && b.type !== "FOLDER" ? -1 : 1;
    });

  return (
    <div className={styles.CustomTable}>
      {search ? (
        <h2 className={styles.title}>{filteredData.length || "No"} results </h2>
      ) : (
        <Breadcrumbs className={styles.title} />
      )}
      <header
        className={cs([styles.tr, selectedItems.length > 0 && styles.active])}
      >
        <Checkbox
          name="All"
          onChange={handleSelectAll}
          isChecked={selectedItems.length === initialData?.length}
        />
        {selectedItems.length > 0 ? (
          <ul className={styles.actions}>
            {getMenuItems(selectedItems)?.map((el, index) => (
              <li className={styles.action} key={index}>
                <Icon name={el} /> {menuTitle[el]}
              </li>
            ))}
          </ul>
        ) : (
          <>
            <div className={styles.th}>
              <div>Name</div>
              <img
                className={cs([styles.arrow, sorting.name && styles.up])}
                src={arrowDown}
                alt="sort"
                onClick={() => handleSort("name")}
              />
            </div>
            <div className={styles.th}>
              <FilterType
                onChange={handleFilter}
                selectAll={selectAllTypes}
                selectedTypes={selectedTypes}
              />
            </div>
            <div className={styles.th}>
              <div>Attributes</div>
            </div>
            <div className={styles.th}>
              <div>Modification Date</div>
              <img
                className={cs([styles.arrow, sorting.createdAt && styles.up])}
                src={arrowDown}
                alt="sort"
                onClick={() => handleSort("createdAt")}
              />
            </div>
          </>
        )}
      </header>
      {!isDragActive && (
        <>
          {filteredData?.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => dispatch(setDestinationFolder(item.name))}
              onMouseLeave={() => dispatch(setDestinationFolder(""))}
            >
              <TableRow
                onChange={() => handleSelectItem(item)}
                selectedItems={selectedItems}
                {...item}
              />
            </div>
          ))}

          <NoTableData
            isSearch={!!search}
            isNoFilteredData={filteredData?.length === 0}
            isNoData={initialData?.length === 0}
            selectedTypes={selectedTypes}
          />
        </>
      )}
      {renderDropzone()}
    </div>
  );
};

import { toast } from "react-toastify";

import successIcon from "assets/img/toaster/success.svg";
import errorIcon from "assets/img/toaster/error.svg";
import warningIcon from "assets/img/toaster/warning.svg";
import infoIcon from "assets/img/toaster/info.svg";

export const toastSuccess = (text: string) => {
  toast.success(text, {
    icon: () => <img src={successIcon} alt="success" />,
    toastId: text,
  });
};

export const toastError = (text: string) => {
  toast.error(text, {
    icon: () => <img src={errorIcon} alt="error" />,
    toastId: text,
  });
};

export const toastWarning = (text: string) => {
  toast.warning(text, {
    icon: () => <img src={warningIcon} alt="warning" />,
    toastId: text,
  });
};

export const toastInfo = (text: string) => {
  toast.info(text, {
    icon: () => <img src={infoIcon} alt="info" />,
    toastId: text,
  });
};

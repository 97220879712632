import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const SettingsIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0003 12.5C11.381 12.5 12.5003 11.3807 12.5003 10C12.5003 8.61932 11.381 7.50003 10.0003 7.50003C8.61961 7.50003 7.50033 8.61932 7.50033 10C7.50033 11.3807 8.61961 12.5 10.0003 12.5Z"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 10.7334V9.26669C1.66699 8.40003 2.37533 7.68336 3.25033 7.68336C4.75866 7.68336 5.37533 6.6167 4.61699 5.30836C4.18366 4.55836 4.44199 3.58336 5.20033 3.15003L6.64199 2.32503C7.30033 1.93336 8.15033 2.1667 8.54199 2.82503L8.63366 2.98336C9.38366 4.2917 10.617 4.2917 11.3753 2.98336L11.467 2.82503C11.8587 2.1667 12.7087 1.93336 13.367 2.32503L14.8087 3.15003C15.567 3.58336 15.8253 4.55836 15.392 5.30836C14.6337 6.6167 15.2503 7.68336 16.7587 7.68336C17.6253 7.68336 18.342 8.39169 18.342 9.26669V10.7334C18.342 11.6 17.6337 12.3167 16.7587 12.3167C15.2503 12.3167 14.6337 13.3834 15.392 14.6917C15.8253 15.45 15.567 16.4167 14.8087 16.85L13.367 17.675C12.7087 18.0667 11.8587 17.8334 11.467 17.175L11.3753 17.0167C10.6253 15.7084 9.39199 15.7084 8.63366 17.0167L8.54199 17.175C8.15033 17.8334 7.30033 18.0667 6.64199 17.675L5.20033 16.85C4.44199 16.4167 4.18366 15.4417 4.61699 14.6917C5.37533 13.3834 4.75866 12.3167 3.25033 12.3167C2.37533 12.3167 1.66699 11.6 1.66699 10.7334Z"
        stroke={color || palette.black}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;

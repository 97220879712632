import { ChangeEvent, FC } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { cs } from "utils";

import { Icon, IconName } from "../Icon";

import styles from "./styles.module.scss";

type InputProps = {
  className?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickOutside?: () => void;
  label?: string;
  name?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  isAutofocus?: boolean;
  error?: boolean | string;
  addText?: string;
  iconStart?: IconName;
  iconEnd?: IconName;
  iconStartType?: "stroke" | "fill";
  iconEndType?: "stroke" | "fill";
  iconStartAction?: () => void;
  iconEndAction?: () => void;
  isClearButton?: boolean;
  onClear?: () => void;
};

export const Input: FC<InputProps> = ({
  value = "",
  onChange = () => {},
  onClickOutside = () => {},
  name,
  type,
  label,
  className,
  disabled,
  placeholder,
  isAutofocus = false,
  error,
  addText,
  iconStart,
  iconEnd,
  iconStartType,
  iconEndType,
  iconStartAction,
  iconEndAction,
  isClearButton,
  onClear,
}) => {
  return (
    <OutsideClickHandler onOutsideClick={onClickOutside}>
      <div
        className={cs([
          styles.Input,
          className,
          disabled && styles.disabled,
          value && styles.withValue,
          error && styles.withError,
        ])}
      >
        <div className={styles.label}>{label}</div>
        <div
          className={cs([
            styles.inputWithIcon,
            iconEnd && styles.inputWithSecondIcon,
          ])}
        >
          {iconStart && (
            <Icon
              name={iconStart}
              size={20}
              className={cs([
                iconStartType && styles[iconStartType],
                iconStartAction && styles.clickable,
              ])}
              action={iconStartAction}
            />
          )}
          <input
            disabled={disabled}
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            autoComplete="off"
            placeholder={placeholder}
            className={styles.originalInput}
            autoFocus={isAutofocus}
          />
        </div>
        {iconEnd && (
          <Icon
            name={iconEnd}
            size={20}
            className={cs([
              iconEndType && styles[iconEndType],
              iconEndAction && styles.clickable,
            ])}
            action={iconEndAction}
          />
        )}
        {isClearButton && (
          <Icon
            name="close-in-circle"
            size={20}
            className={styles.clearButton}
            action={onClear}
          />
        )}
        <div className={styles.addText}>{error || addText}</div>
      </div>
    </OutsideClickHandler>
  );
};

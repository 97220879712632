import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector, auth } from "store";
import { setForgotEmail } from "store/auth";
import { Auth } from "api";

import { Input, Button } from "components/UI";
import { PATHES } from "constants/pathes";
import { validateEmail } from "utils";

import styles from "./styles.module.scss";

export const ForgotPassword: FC = () => {
  const { editableEmail } = useAppSelector(auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleChangeEmail = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setError("");
    const emailValidationErrors = value && validateEmail(value);
    if (emailValidationErrors) {
      setError(emailValidationErrors[0]);
    }
    setEmail(value);
  };

  const handleClearEmail = () => {
    setError("");
    setEmail("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email && !error) {
      dispatch(setForgotEmail(email));
      const res = await Auth.forgotPassword({
        email,
      });
      res?.message && navigate(PATHES.ENTER_CODE);
    }
  };

  return (
    <form className={styles.ForgotPassword}>
      <h1 className={styles.title}>Provide {editableEmail} email</h1>
      <p className={styles.info}>Provide your email for verification</p>
      <Input
        type="email"
        onChange={handleChangeEmail}
        value={email}
        name="email"
        error={error}
        iconEnd={email ? "close-in-circle" : undefined}
        iconEndType="stroke"
        iconEndAction={handleClearEmail}
      />
      <Button
        variant="primary"
        title="Send verification code"
        onClick={handleSubmit}
        isDisabled={!email || !!error}
        className={styles.submitButton}
      />
    </form>
  );
};

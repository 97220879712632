import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const CheckMarkIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 11 / 8; // width / height
  let height = 8;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 3.5L4 7.5L10.5 0.5"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckMarkIcon;

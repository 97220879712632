import { FC } from "react";

import { palette } from "utils";

import styles from "./styles.module.scss";

type CircleProgressBarProps = {
  size?: number;
  percentage?: number;
  color?: string;
};

export const CircleProgressBar: FC<CircleProgressBarProps> = ({
  size = 20,
  percentage = 0,
  color,
}) => {
  const r = size / 3;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - percentage) * circ) / 100;
  return (
    <svg width={size} height={size} className={styles.CircleProgressBar}>
      {/* <g transform={`rotate(-90 "${size / 2} ${size / 2}"})`}> */}
      <circle
        r={r}
        cx={size / 2}
        cy={size / 2}
        fill="transparent"
        stroke={color || palette.grey30}
        strokeWidth={size / 6}
        strokeDasharray={circ}
        strokeDashoffset="0"
      />
      <circle
        r={r}
        cx={size / 2}
        cy={size / 2}
        fill="transparent"
        stroke={color || palette.actionGreen}
        strokeWidth={size / 6}
        strokeDasharray={circ}
        strokeDashoffset={percentage ? strokePct : 0}
      />
      {/* </g> */}
    </svg>
  );
};

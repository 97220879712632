import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { IAuthResponse } from "types";
import { toastError } from "utils";
import { BASE_URL } from "../constants";
import { setSession, getSession, removeSession } from "./storage";

export const $http = axios.create({
  baseURL: BASE_URL,
  timeout: 50000,
  timeoutErrorMessage: "Timeout Error",
  withCredentials: false,
});

// const requestHandler = async (config: InternalAxiosRequestConfig) => config;

// const errorHandler = (err: AxiosError) => Promise.reject(err);

const connectionErrorStatuses: Record<string, string> = {
  ERR_NETWORK: "ERR_NETWORK",
  ECONNABORTED: "ECONNABORTED",
};

const handleHttpError = (error: any) => {
  if (axios.isAxiosError(error)) {
    const response = error?.response;

    // Connection errors
    if (connectionErrorStatuses[error.code as string]) {
      toastError(
        "Oops, there was a problem reaching our servers. \nPlease make sure your Internet connection is working, and try again. \nIf the problem persist, contact support.",
      );
      return Promise.reject(new Error(error.message));
    }
    //

    // Server errors
    if (response && response.status >= 500) {
      toastError(
        "Oops, looks like our servers returned an unexpected error. \nPlease try again in a while and contact support if the problem persists.",
      );
      return Promise.reject(new Error(error.message));
    }
    //

    // Too many requests
    if (response && response.status === 429) {
      toastError(
        "We apologize for any inconvenience caused. \nKindly reduce the frequency of your requests and try again later. Thank you for your patience and understanding.",
      );
      return Promise.reject(new Error(error.message));
    }

    if (axios.isCancel(error)) {
      console.log("request cancelled");
      return Promise.reject(new Error(error.message));
    }

    return Promise.reject(error);
  }

  return Promise.reject(error);
};

// $http.interceptors.request.use(requestHandler, errorHandler);

$http.interceptors.request.use(
  (request) => {
    const token = getSession()?.accessToken;

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

$http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const config = error?.config;
    const status = error?.response?.status;

    if (status === 401) {
      return Promise.reject(error);
    }

    if (config?.raw) {
      return Promise.reject(error);
    }

    return handleHttpError(error);
  },
);

// export const callApiWithToken = (
//   token: string,
//   url: string,
//   options = { headers: {} },
// ) => {
//   return $http({
//     ...options,
//     url,
//     headers: {
//       Authorization: `Bearer ${token}`,
//       ...options.headers,
//     },
//   });
// };

// export const refresh = async (url: string, options = { headers: {} }) => {
//   try {
//     const newTokens = await $http.get("/refresh", {
//       withCredentials: true,
//     });

//     if (newTokens?.data?.token) {
//       setSession(newTokens.data.token);
//       return await callApiWithToken(newTokens.data.token, url, options);
//     }
//     return Promise.reject(new Error("Unauthorized"));
//   } catch (e) {
//     removeSession();
//     return Promise.reject(e);
//   }
// };

const refreshAccessToken = async () => {
  const { accessToken, refreshToken } = getSession();

  try {
    if (!accessToken || !refreshToken) {
      throw new Error("Session expired");
    }

    const res = await axios.post<IAuthResponse>(`${BASE_URL}/auth/refresh`, {
      headers: { Authorization: `Bearer ${refreshToken}` },
    });

    setSession({
      accessToken: res.data.accessToken,
      refreshToken: res.data.refreshToken,
      user: res.data.user,
    });
    return Promise.resolve();
  } catch (e) {
    removeSession();
    return Promise.reject(new Error("Unauthorized"));
  }
};

createAuthRefreshInterceptor($http, refreshAccessToken, {
  pauseInstanceWhileRefreshing: true,
  shouldRefresh(error): boolean {
    const { accessToken } = getSession();
    return !!accessToken && error?.response?.status === 401;
  },
});

// export const authorizedRequest = async (
//   url: string,
//   options = { headers: {} },
// ) => {
//   const storedTokens = getSession();
//   if (storedTokens?.accessToken) {
//     try {
//       return await callApiWithToken(storedTokens.accessToken, url, options);
//     } catch (err) {
//       return Promise.reject(err);
//     }
//   }
//   return Promise.reject(new Error("Unauthorized"));
// };

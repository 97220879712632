import { FC, useState } from "react";

import { Icon, Button } from "components/UI";
import { cs, palette } from "utils";

import styles from "./styles.module.scss";

type PremiumModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  hasTrial?: boolean;
};

export const PremiumModal: FC<PremiumModalProps> = ({
  hasTrial,
  onClose,
  onSubmit,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<string>("yearly");

  const isMonthly = selectedPlan === "monthly";
  const isYearly = selectedPlan === "yearly";

  const handleSubmit = () => {
    console.log("submit selectedPlan:", selectedPlan);
    onSubmit();
    onClose();
  };

  return (
    <div className={styles.PremiumModal}>
      <h2 className={styles.title}>
        {hasTrial ? "Get Premium Subscription" : "Get Trial Premium"}
      </h2>
      <div className={styles.privileges}>
        <div>Privileges:</div>
        <div className={styles.centered}>Premium:</div>
        <div className={styles.centered}>Basic:</div>
        <div>Certified docs</div>
        <div className={styles.centered}>
          <Icon name="check-in-flower" size={24} color={palette.actionGreen} />
        </div>
        <div className={styles.centered}>
          <Icon name="close-in-circle" size={24} color={palette.grey70} />
        </div>
        <div>No watermarks</div>
        <div className={styles.centered}>
          <Icon name="check-in-flower" size={24} color={palette.actionGreen} />
        </div>
        <div className={styles.centered}>
          <Icon name="close-in-circle" size={24} color={palette.grey70} />
        </div>
        <div>Devices sync</div>
        <div className={cs([styles.active, styles.centered])}>15GB</div>
        <div className={cs([styles.inactive, styles.centered])}>200MB</div>
      </div>
      <h2 className={styles.letsText}>Let’s choose the plan!</h2>
      <div className={styles.cards}>
        <div
          className={cs([styles.card, isMonthly && styles.active])}
          onClick={() => setSelectedPlan("monthly")}
        >
          <div className={styles.title}>Monthly</div>
          <div className={styles.price}>15$</div>
          <div className={styles.info}>Easy start</div>
        </div>
        <div
          className={cs([styles.card, isYearly && styles.active])}
          onClick={() => setSelectedPlan("yearly")}
        >
          <div className={styles.title}>Yearly</div>
          <div className={styles.price}>100$</div>
          <div className={styles.info}>Only $8 per month</div>
        </div>
      </div>
      <Button
        variant="outlined"
        title="Start 14-days trial"
        onClick={handleSubmit}
        className={styles.submitButton}
      />
      <Button
        variant="textWhite"
        size="sm"
        title="Not now"
        onClick={onClose}
        className={styles.closeButton}
      />
    </div>
  );
};

import { useState, ChangeEvent, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { useAppDispatch } from "store";
import { setDocument } from "store/dashboard";

import { Modal } from "components/UI";
import { UploadModal, CancleUploadingModal } from "components";

import { toastError, sleep, cs } from "utils";
import { File as FileT } from "types";

import fileBig from "assets/img/icons/file-big.svg";

import styles from "./styles.module.scss";

export const useUpload = () => {
  const dispatch = useAppDispatch();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isUploadModal, setIsUploadModal] = useState<boolean>(false);
  const [filesToSave, setFilesToSave] = useState<FileT[]>([]);
  const [isCancelModal, setIsCancelModal] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<FileT | null>(null);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const uploadFiles = useCallback(
    async (files: File[] | FileList) => {
      const isSomeErrorType = Array.from(files)?.some(
        (el) => el.type !== "application/pdf",
      );
      const isSomePdfType = Array.from(files)?.some(
        (el) => el.type === "application/pdf",
      );

      if (isSomeErrorType) {
        toastError("Sorry, this format is not supported");
      } else if (isSomePdfType) {
        const base64File = await toBase64(files[0]);
        dispatch(setDocument(base64File as string));
        const filesArr = Array.from(files)?.filter(
          (el) => el.type === "application/pdf",
        );
        const newFilesToSave = filesArr.map((el, index) => ({
          status: "success",
          title: el.name,
          dir: "My docs",
          order: index,
        }));

        setFilesToSave(newFilesToSave);
        setIsUploading(true);
        setIsUploadModal(true);
        await sleep(2000);
        setFilesToSave((prevState) => [
          ...prevState.slice(1),
          {
            ...prevState[0],
            status: "error",
          },
        ]);
        await sleep(2000);
        setIsUploading(false);
      }
    },
    [dispatch],
  );

  const handleUploadFile = async ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    files && uploadFiles(files);
    target.value = "";
  };

  const handleCancelUpload = (file: FileT) => {
    const newFiles = [
      ...filesToSave.filter((el) => el.title !== file.title),
      {
        ...file,
        status: "canceled",
      },
    ];
    setFilesToSave(newFiles);
  };

  const handleCancelAll = () => {
    const newFiles = [
      ...filesToSave.map((el) => ({
        ...el,
        status: "canceled",
      })),
    ];
    setFilesToSave(newFiles);
  };

  const handleOpenCancelModal = (file: FileT) => {
    setSelectedFile(file);
    setIsCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setSelectedFile(null);
    setIsCancelModal(false);
  };

  const handleCancelUploading = () => {
    selectedFile && handleCancelUpload(selectedFile);
  };

  const UploadModalWrapped = () => (
    <>
      <Modal isShowed={isCancelModal}>
        <CancleUploadingModal
          onClose={handleCloseCancelModal}
          onSubmit={handleCancelUploading}
        />
      </Modal>
      <Modal isShowed={isUploadModal} className={styles.UploadModal} noDarkness>
        <UploadModal
          isUploading={isUploading}
          onClose={() => setIsUploadModal(false)}
          files={filesToSave}
          onCancel={handleOpenCancelModal}
          onCancelAll={handleCancelAll}
        />
      </Modal>
    </>
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles && uploadFiles(acceptedFiles);
    },
    [uploadFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const renderDropzone = () => (
    <>
      <div
        className={cs([styles.DropZone, isDragActive && styles.active])}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive && (
          <>
            <img src={fileBig} alt="file" />
            <h2 className={styles.title}>Drop files here to upload them</h2>
            <p className={styles.info}>Info about limitations (format, size)</p>
          </>
        )}
      </div>
    </>
  );

  return {
    isDragActive,
    renderDropzone,
    UploadModalWrapped,
    onUpload: handleUploadFile,
  };
};

import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector, auth } from "store";
import { setAuthenticated, setUser, setForgotEmail } from "store/auth";
import { Auth, setSession } from "api";

import { Input, Button, Icon } from "components/UI";
import { PATHES } from "constants/pathes";
import { validatePassword, cs } from "utils";

import styles from "./styles.module.scss";

export const CreatePassword: FC = () => {
  const { editablePassword, verificationId, email, forgotEmail } =
    useAppSelector(auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState<string>("");
  const [errors, setErrors] = useState<string[]>([]);
  const [isPasswordVisible, setIsPassVisible] = useState<boolean>(false);
  const requirements = [
    "Minimum eight characters",
    "One uppercase letter",
    "One lowercase letter",
    "One number",
  ];

  const handleChangePassword = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (value.length > 40) {
      return;
    }

    setErrors([]);
    const validateErrors = validatePassword(value);
    setErrors(validateErrors);
    setPassword(value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password && errors.length === 0) {
      let res;
      if (forgotEmail) {
        res = await Auth.resetPassword({
          password,
          verificationId,
        });
      } else {
        res = await Auth.signUp({
          password,
          email,
          verificationId,
        });
      }

      if (res?.accessToken) {
        const { accessToken, refreshToken, user } = res;
        dispatch(setUser(user));
        setSession({
          accessToken,
          refreshToken,
          user,
        });
        dispatch(setAuthenticated(true));
        dispatch(setForgotEmail(""));
        navigate(editablePassword ? PATHES.SETTINGS : PATHES.HOME);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setIsPassVisible((prevState) => !prevState);
  };

  return (
    <form className={styles.CreatePassword}>
      <h1 className={styles.title}>
        Create {forgotEmail ? "new" : ""} password
      </h1>
      <Input
        type={isPasswordVisible ? "text" : "password"}
        onChange={handleChangePassword}
        value={password}
        name="password"
        iconEnd={isPasswordVisible ? "eye-opened" : "eye-closed"}
        iconEndType="stroke"
        iconEndAction={togglePasswordVisibility}
      />
      <ul className={styles.requirements}>
        {requirements.map((item, index) => (
          <li
            key={index}
            className={cs([
              styles.requirement,
              password && !errors.includes(item) && styles.success,
            ])}
          >
            <Icon name="done-success" size={12} /> {item}
          </li>
        ))}
      </ul>
      <Button
        variant="primary"
        title="Save password"
        onClick={handleSubmit}
        isDisabled={!password || errors.length !== 0}
        className={styles.submitButton}
      />
    </form>
  );
};

import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "store";
import { setAuthenticated, setUser } from "store/auth";
import { Auth, setSession, removeSession } from "api";

import { Input, Button } from "components/UI";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

export const SignIn: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isPasswordVisible, setIsPassVisible] = useState<boolean>(false);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    removeSession();
    e.preventDefault();
    if (!email.includes("@")) {
      setIsError(true);
    } else {
      const res = await Auth.signIn({
        password,
        email,
      });

      if (res?.accessToken) {
        const { accessToken, refreshToken, user } = res;
        dispatch(setUser(user));
        setSession({
          accessToken,
          refreshToken,
          user,
        });
        dispatch(setAuthenticated(true));
        navigate(PATHES.HOME);
      }
    }
  };

  const togglePasswordVisibility = () => {
    setIsPassVisible((prevState) => !prevState);
  };

  const handleClearEmail = () => {
    setIsError(false);
    setEmail("");
  };

  return (
    <form className={styles.SignIn}>
      <h1 className={styles.title}>Log in</h1>
      <Input
        type="email"
        label="Email"
        onChange={handleChangeEmail}
        value={email}
        name="email"
        error={isError}
        iconEnd={email ? "close-in-circle" : undefined}
        iconEndType="stroke"
        iconEndAction={handleClearEmail}
      />
      <Input
        type={isPasswordVisible ? "text" : "password"}
        label="Password"
        onChange={handleChangePassword}
        value={password}
        error={isError ? "Email or password is incorrect" : ""}
        name="password"
        iconEnd={isPasswordVisible ? "eye-opened" : "eye-closed"}
        iconEndType="stroke"
        iconEndAction={togglePasswordVisibility}
      />
      <Button
        variant="textBlack"
        size="sm"
        title="Forgot password?"
        onClick={() => navigate(PATHES.FORGOT_PASSWORD)}
        className={styles.forgotButton}
      />
      <Button
        variant="primary"
        title="Next"
        onClick={handleSubmit}
        isDisabled={!email || !password}
      />
      <div className={styles.info}>
        <span>Don’t have an account?</span>
      </div>
      <Button
        variant="textBlack"
        size="sm"
        title="Sign up"
        onClick={() => navigate(PATHES.SIGN_UP)}
        className={styles.signUpButton}
      />
    </form>
  );
};

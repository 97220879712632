import { FC, useState, ChangeEvent } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import { Icon, RadioButton } from "components/UI";
import { cs, folderItems } from "utils";
import { SelectedType } from "types";

import styles from "./styles.module.scss";

type FilterTypeProps = {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  selectAll: () => void;
  selectedTypes: SelectedType[];
};

export const FilterType: FC<FilterTypeProps> = ({
  onChange = () => {},
  selectAll = () => {},
  selectedTypes,
}) => {
  const [isFilterMenu, setIsFilterMenu] = useState<boolean>(false);

  const label =
    selectedTypes.length === 3 || selectedTypes.length === 0
      ? "All"
      : folderItems
          .filter((el) => selectedTypes.includes(el.value))
          .map((el) => el.title)
          .join(", ");

  const toggleMenu = () => {
    setIsFilterMenu((prevState) => !prevState);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsFilterMenu(false)}>
      <div className={styles.FilterType}>
        <div className={styles.label} onClick={toggleMenu}>
          Type: {label}
        </div>
        {isFilterMenu && (
          <ul className={styles.menu}>
            <RadioButton
              onChange={selectAll}
              title="All"
              name="all"
              isChecked={selectedTypes.length === folderItems.length}
            />
            {folderItems.map((el, index) => (
              <li key={index}>
                <RadioButton
                  onChange={onChange}
                  title={el.title}
                  name={el.value}
                  isChecked={selectedTypes.includes(el.value)}
                />
              </li>
            ))}
          </ul>
        )}
        <Icon
          name="chevron-left-empty"
          className={cs([styles.arrow, isFilterMenu && styles.down])}
          action={toggleMenu}
        />
      </div>
    </OutsideClickHandler>
  );
};

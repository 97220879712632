import { DataType, MenuItem, SelectedType } from "types";

export const getMenuItems = (
  selectedItems: DataType[],
  type?: SelectedType,
): MenuItem[] => {
  const selectedFilesCount = selectedItems?.filter(
    (el) => el.type === "PDF" || el.type === "PDF_CERTIFIED",
  )?.length;
  const selectedFolderCount = selectedItems?.filter(
    (el) => el.type === "FOLDER",
  )?.length;

  if (
    (selectedFilesCount > 0 && selectedFolderCount > 0) ||
    selectedFolderCount > 1
  ) {
    return ["move", "trash"];
  }
  if (selectedFilesCount > 1 && selectedFolderCount === 0) {
    return ["share", "move", "download", "trash"];
  }
  if (
    (selectedFilesCount === 1 && selectedFolderCount === 0) ||
    (selectedItems.length === 0 &&
      type &&
      ["PDF", "PDF_CERTIFIED"].includes(type))
  ) {
    return ["share", "move", "download", "rename", "trash"];
  }
  if (
    (selectedFilesCount === 0 && selectedFolderCount === 1) ||
    (selectedItems.length === 0 && type === "FOLDER")
  ) {
    return ["move", "rename", "trash"];
  }
  return [];
};

export const menuTitle: Record<MenuItem, string> = {
  [`add-folder`]: "Create folder",
  rename: "Rename",
  move: "Move",
  trash: "Delete",
  share: "Share",
  download: "Download",
};

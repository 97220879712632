import { FC, memo, ChangeEvent } from "react";

import { cs } from "utils";

import styles from "./styles.module.scss";

type RadioButtonProps = {
  title?: string;
  name?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  isDisabled?: boolean;
  isChecked?: boolean;
  size?: number;
};

export const RadioButton: FC<RadioButtonProps> = memo(
  ({ title, name, onChange, onClick, isDisabled, isChecked, size }) => (
    <label
      className={cs([
        styles.RadioButton,
        title && styles.withTitle,
        isDisabled && styles.disabled,
      ])}
      htmlFor={`${name}RadioButtonComponent`}
    >
      <input
        id={`${name}RadioButtonComponent`}
        type="checkbox"
        className={styles.hiddenRadioButton}
        name={name}
        checked={isChecked}
        onChange={onChange}
        onClick={onClick}
        disabled={isDisabled}
      />
      <span
        className={cs([styles.checkCircle, isChecked && styles.checked])}
        style={{
          width: size ? `${size}px` : "20px",
          height: size ? `${size}px` : "20px",
        }}
      />
      {title && <span className={styles.title}>{title}</span>}
    </label>
  ),
);

import { Fragment, FC } from "react";
import { useDroppable } from "@dnd-kit/core";

import { useAppSelector, dashboard } from "store";

import { SidebarFolder } from "components";
import { DataType } from "types";
import { cs } from "utils";

import styles from "./styles.module.scss";

type SidebarFoldersProps = {
  handleOpenFolder: (folder: string) => void;
  openedFolders: string[];
  isOverRoot: boolean;
  rootName: string;
};

export const SidebarFolders: FC<SidebarFoldersProps> = ({
  handleOpenFolder,
  openedFolders,
  isOverRoot,
  rootName,
}) => {
  const { foldersData, rootFolder } = useAppSelector(dashboard);
  const { isOver, node, setNodeRef } = useDroppable({
    id: "droppable",
  });

  const isOpenedEl = (name: string, id: string) => {
    return (
      (isOver && node?.current?.outerText.includes(name)) ||
      !!(isOverRoot && rootName) ||
      openedFolders.includes(id)
    );
  };

  const renderSubFolders = (elements: DataType[]): any => {
    return elements
      .filter((el) => el.type === "FOLDER")
      .map((el, index): any => {
        if (!el.childrenCount) {
          return (
            <li
              className={styles.folder}
              key={`${el.id}${index}`}
              style={{ marginLeft: `${(el.parentsCount || 1) * 10}px` }}
            >
              <SidebarFolder
                title={el.name}
                id={el.id}
                childrenCount={el.childrenCount}
                isOpened={
                  openedFolders.includes(el.id) || isOpenedEl(el.name, el.id)
                }
                handleOpenFolder={() => handleOpenFolder(el.id)}
              />
            </li>
          );
        }
        if (el.childFolders) {
          return [
            <Fragment key={`${el.id}${index}`}>
              <li
                className={styles.folder}
                style={{ marginLeft: `${(el.parentsCount || 1) * 10}px` }}
              >
                <SidebarFolder
                  title={el.name}
                  id={el.id}
                  childrenCount={el.childrenCount}
                  isOpened={
                    openedFolders.includes(el.id) || isOpenedEl(el.name, el.id)
                  }
                  handleOpenFolder={() => handleOpenFolder(el.id)}
                />
              </li>
              {isOpenedEl(el.name, el.id) && renderSubFolders(el.childFolders)}
            </Fragment>,
          ];
        }
        return <></>;
      });
  };

  return (
    <>
      {Object.entries(foldersData).map((item, indexRoot) => (
        <Fragment key={`${item[0]}${indexRoot}`}>
          <ul
            className={cs([
              styles.SidebarFolders,
              isOpenedEl(rootFolder.name, rootFolder.id) && styles.visible,
            ])}
            ref={setNodeRef}
          >
            {renderSubFolders(item[1])}
          </ul>
        </Fragment>
      ))}
    </>
  );
};

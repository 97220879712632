import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const UserFilledIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 14 / 18; // width / height
  let height = 18;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.13327 8.05817C7.04993 8.04984 6.94993 8.04984 6.85827 8.05817C4.87493 7.9915 3.29993 6.3665 3.29993 4.3665C3.29993 2.32484 4.94993 0.666504 6.99993 0.666504C9.0416 0.666504 10.6999 2.32484 10.6999 4.3665C10.6916 6.3665 9.1166 7.9915 7.13327 8.05817Z"
        fill={color || palette.black}
      />
      <path
        d="M2.9666 11.1332C0.949935 12.4832 0.949935 14.6832 2.9666 16.0248C5.25827 17.5582 9.0166 17.5582 11.3083 16.0248C13.3249 14.6748 13.3249 12.4748 11.3083 11.1332C9.02493 9.60817 5.2666 9.60817 2.9666 11.1332Z"
        fill={color || palette.black}
      />
      <path
        d="M7.13327 8.05817C7.04993 8.04984 6.94993 8.04984 6.85827 8.05817C4.87493 7.9915 3.29993 6.3665 3.29993 4.3665C3.29993 2.32484 4.94993 0.666504 6.99993 0.666504C9.0416 0.666504 10.6999 2.32484 10.6999 4.3665C10.6916 6.3665 9.1166 7.9915 7.13327 8.05817Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.9666 11.1332C0.949935 12.4832 0.949935 14.6832 2.9666 16.0248C5.25827 17.5582 9.0166 17.5582 11.3083 16.0248C13.3249 14.6748 13.3249 12.4748 11.3083 11.1332C9.02493 9.60817 5.2666 9.60817 2.9666 11.1332Z"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserFilledIcon;

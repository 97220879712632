import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const FolderEmptyIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3337 9.1665V14.1665C18.3337 17.4998 17.5003 18.3332 14.167 18.3332H5.83366C2.50033 18.3332 1.66699 17.4998 1.66699 14.1665V5.83317C1.66699 2.49984 2.50033 1.6665 5.83366 1.6665H7.08366C8.33366 1.6665 8.60866 2.03317 9.08366 2.6665L10.3337 4.33317C10.6503 4.74984 10.8337 4.99984 11.667 4.99984H14.167C17.5003 4.99984 18.3337 5.83317 18.3337 9.1665Z"
        stroke={color || palette.black}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default FolderEmptyIcon;

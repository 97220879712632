import { FC } from "react";

import { Icon, Button } from "components/UI";
import { palette } from "utils";

import mainLogoBlack from "assets/img/main-logo-black.png";

import styles from "./styles.module.scss";

type TrialActivatedModalProps = {
  onClose: () => void;
};

export const TrialActivatedModal: FC<TrialActivatedModalProps> = ({
  onClose,
}) => {
  return (
    <div className={styles.TrialActivatedModal}>
      <header className={styles.header}>
        <div className={styles.content}>
          <h1 className={styles.title}>Free trial activated!</h1>
          <img src={mainLogoBlack} alt="logo" width="70" height="19" />
        </div>
      </header>

      <div className={styles.info}>
        <h2 className={styles.title}>Free 7-Day Trial</h2>
        <p>Active until 23 Apr 2023</p>
        <ul className={styles.privileges}>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            Certified docs
          </li>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            No watermarks
          </li>
          <li className={styles.privilege}>
            <Icon
              name="check-in-flower"
              size={24}
              color={palette.actionGreen}
            />
            15GB for devices synс
          </li>
        </ul>
        <Button
          variant="outlined"
          title="Great!"
          onClick={onClose}
          className={styles.submitButton}
        />
      </div>
    </div>
  );
};

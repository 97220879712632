export const authErrors = (errMessage: string) => {
  switch (errMessage) {
    case "api.errors.auth.signup.verificationCodeExpired":
    case "api.errors.auth.forgotPassword.verificationCodeExpired":
    case "api.errors.settings.changeEmailVerificationCodeExpired":
      return "Verification code expired";
    case "api.errors.auth.signup.wrongVerificationCode":
    case "api.errors.auth.forgotPassword.wrongVerificationCode":
    case "api.errors.settings.changeEmailWrongVerificationCode":
      return "Wrong verification code";
    case "api.errors.auth.signup.verificationCodeRecentlySent":
    case "api.errors.auth.forgotPassword.verificationCodeRecentlySent":
    case "api.errors.settings.changeEmailVerificationCodeRecentlySent":
      return "Verification code recently sent";
    case "api.errors.auth.forgotPassword.verificationCodeIsUsed":
    case "api.errors.settings.changeEmailVerificationCodeIsUsed":
      return "Verification code is used";
    case "api.errors.auth.signup.invalidPassword":
    case "api.errors.auth.resetPassword.invalidPassword":
      return "Password is incorrect";
    case "api.errors.auth.signup.invalidEmail":
    case "api.errors.auth.login.invalidEmail":
    case "api.errors.forgotPassword.invalidEmail":
    case "api.errors.forgotPassword.emailNotFound":
      return "Email is incorrect";
    case "api.errors.login.incorrectEmailOrPassword":
      return "Email or password is incorrect";
    case "api.errors.auth.signup.userExists":
      return "User already exists";
    case "api.errors.auth.userWithEmailAlreadyExists":
      return "User with this email already exists";
    case "api.errors.auth.resetPassword.invalidVerification":
      return "Invalid verification";

    default:
      return "Something went wrong";
  }
};

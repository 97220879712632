import { FC, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { PATHES } from "constants/pathes";
import { Icon, Input, Button } from "components/UI";

import styles from "./styles.module.scss";

type ConfirmPasswordModalProps = {
  onClose?: () => void;
  onSubmit: () => void;
};

export const ConfirmPasswordModal: FC<ConfirmPasswordModalProps> = ({
  onClose,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isPasswordVisible, setIsPassVisible] = useState<boolean>(false);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    setIsError(false);
    if (password !== "111aaaAAA") {
      setIsError(true);
    } else {
      onSubmit();
    }
  };

  const togglePasswordVisibility = () => {
    setIsPassVisible((prevState) => !prevState);
  };

  return (
    <div className={styles.ConfirmPasswordModal}>
      <Icon name="close" className={styles.close} action={onClose} />
      <h2 className={styles.title}>Provide your password</h2>
      <p className={styles.info}>
        Provide your password for security verification
      </p>
      <Input
        className={styles.input}
        type={isPasswordVisible ? "text" : "password"}
        label="Password"
        onChange={handleChangePassword}
        value={password}
        error={isError && "Invalid password"}
        name="password"
        iconEnd={isPasswordVisible ? "eye-opened" : "eye-closed"}
        iconEndType="stroke"
        iconEndAction={togglePasswordVisibility}
      />
      <Button
        variant="textBlack"
        size="sm"
        title="Forgot password?"
        onClick={() => navigate(PATHES.CREATE_PASSWORD)}
        className={styles.forgotButton}
      />
      <Button
        variant="primary"
        title="Confirm"
        onClick={handleSubmit}
        isDisabled={!password}
      />
    </div>
  );
};

import { FC } from "react";
import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const LoginIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace,
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <svg
      className={className}
      onClick={action}
      width={width}
      height={height}
      data-tooltip-id={dataTooltipId}
      data-tooltip-content={dataTooltipContent}
      data-tooltip-place={dataTooltipPlace}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.41699 6.3002C7.67533 3.3002 9.21699 2.0752 12.592 2.0752H12.7003C16.4253 2.0752 17.917 3.56686 17.917 7.29186V12.7252C17.917 16.4502 16.4253 17.9419 12.7003 17.9419H12.592C9.24199 17.9419 7.70033 16.7335 7.42533 13.7835"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66699 10H12.4003"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.542 7.2085L13.3337 10.0002L10.542 12.7918"
        stroke={color || palette.black}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoginIcon;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/fonts/ClashDisplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/fonts/ClashDisplay-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../assets/fonts/Urbanist-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../assets/fonts/Urbanist-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Clash";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Clash";
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "Urbanist";
  font-weight: 400;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Urbanist";
  font-weight: 500;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
@keyframes styles_load__TFpve {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
.styles_Spinner__\\+FK1l {
  width: 20px;
  aspect-ratio: 1;
  display: grid;
  mask: conic-gradient(from 22deg, rgba(0, 0, 0, 0.2), #000);
  animation: styles_load__TFpve 1s steps(8) infinite;
  --_g: linear-gradient(currentColor 0 0) 50%;
  background: var(--_g)/34% 8% space no-repeat, var(--_g)/8% 34% no-repeat space;
}
.styles_Spinner__\\+FK1l:before {
  background: var(--_g)/34% 8% space no-repeat, var(--_g)/8% 34% no-repeat space;
  content: "";
  transform: rotate(45deg);
}`, "",{"version":3,"sources":["webpack://./src/scss/_fonts.scss","webpack://./src/components/UI/Spinner/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,gBAAA;EACA,+DAAA;ACCF;ADEA;EACE,oBAAA;EACA,gBAAA;EACA,+DAAA;ACAF;ADGA;EACE,uBAAA;EACA,gBAAA;EACA,+DAAA;ACDF;ADIA;EACE,uBAAA;EACA,gBAAA;EACA,+DAAA;ACFF;AAjBA;EACE;IACE,wBAAA;EAmBF;EAjBA;IACE,wBAAA;EAmBF;AACF;AAhBA;EACE,WAAA;EACA,eAAA;EACA,aAAA;EAEA,0DAAA;EACA,kDAAA;EACA,2CAAA;EACA,8EACE;AAiBJ;AAdE;EACE,8EACE;EAEF,WAAA;EACA,wBAAA;AAcJ","sourcesContent":["@font-face {\n  font-family: \"Clash\";\n  font-weight: 400;\n  src: url(\"../assets/fonts/ClashDisplay-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Clash\";\n  font-weight: 500;\n  src: url(\"../assets/fonts/ClashDisplay-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Urbanist\";\n  font-weight: 400;\n  src: url(\"../assets/fonts/Urbanist-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Urbanist\";\n  font-weight: 500;\n  src: url(\"../assets/fonts/Urbanist-Medium.ttf\") format(\"truetype\");\n}\n","@import \"scss/resources\";\n\n@keyframes load {\n  from {\n    transform: rotate(0turn);\n  }\n  to {\n    transform: rotate(1turn);\n  }\n}\n\n.Spinner {\n  width: 20px;\n  aspect-ratio: 1;\n  display: grid;\n  -webkit-mask: conic-gradient(from 22deg, #0003, #000);\n  mask: conic-gradient(from 22deg, #0003, #000);\n  animation: load 1s steps(8) infinite;\n  --_g: linear-gradient(currentColor 0 0) 50%;\n  background:\n    var(--_g) / 34% 8% space no-repeat,\n    var(--_g) / 8% 34% no-repeat space;\n\n  &:before {\n    background:\n      var(--_g) / 34% 8% space no-repeat,\n      var(--_g) / 8% 34% no-repeat space;\n    content: \"\";\n    transform: rotate(45deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Spinner": `styles_Spinner__+FK1l`,
	"load": `styles_load__TFpve`
};
export default ___CSS_LOADER_EXPORT___;

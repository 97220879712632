import { FC } from "react";
import { Tooltip } from "react-tooltip";

import { calculateSideByRatio, palette } from "utils";
import { IconProps } from "./index";

const RenameIcon: FC<IconProps> = ({
  className,
  color,
  action,
  size,
  dataTooltipId,
  dataTooltipContent,
  dataTooltipPlace = "bottom",
}) => {
  const SIDES_RATIO = 20 / 20; // width / height
  let height = 20;
  if (size) {
    height = size;
  }
  const width = calculateSideByRatio(height, SIDES_RATIO);

  return (
    <>
      <svg
        className={className}
        onClick={action}
        width={width}
        height={height}
        data-tooltip-id={dataTooltipId}
        data-tooltip-content={dataTooltipContent}
        data-tooltip-place={dataTooltipPlace}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 12.55C1.5 15.8395 4.1605 18.5 7.45 18.5L6.5575 17.0125M18.5 7.45C18.5 4.1605 15.8395 1.5 12.55 1.5L13.4425 2.9875"
          stroke={color || palette.black}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 7.5L4.5 0.5L1 7.5M2 5.5H7"
          stroke={color || palette.black}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 11.5H13V14.75M13 14.75V18H18M13 14.75H16.3333"
          stroke={color || palette.black}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {dataTooltipId && (
        <Tooltip
          id={dataTooltipId}
          positionStrategy="fixed"
          delayShow={300}
          noArrow
        />
      )}
    </>
  );
};

export default RenameIcon;

import { FC, useState, ChangeEvent } from "react";

import { Icon, Button, RadioButton } from "components/UI";

import styles from "./styles.module.scss";

type ChangeLanguageModalProps = {
  onClose: () => void;
  onSubmit?: () => void;
};

export const ChangeLanguageModal: FC<ChangeLanguageModalProps> = ({
  onClose,
  onSubmit = () => {},
}) => {
  const initialLang = "English";
  const [lang, setLang] = useState<string>(initialLang);
  const languages = [
    "English",
    "French",
    "German",
    "Italian",
    "Spanish",
    "Portuguese",
  ];

  const handleSubmit = () => {
    console.log("language changed");
    onSubmit();
    onClose();
  };

  const handleChange = ({
    target: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    setLang(name);
  };

  return (
    <div className={styles.ChangeLanguageModal}>
      <Icon name="close" className={styles.close} action={onClose} />
      <h2 className={styles.title}>Change language</h2>
      <p className={styles.info}>
        Full interface will be changed to chosen language.
      </p>
      <ul className={styles.checkList}>
        {languages.map((el, index) => (
          <li key={index}>
            <RadioButton
              onChange={handleChange}
              title={el}
              name={el}
              isChecked={lang === el}
            />
          </li>
        ))}
      </ul>
      <div className={styles.buttons}>
        <Button variant="secondary" title="Cancel" onClick={onClose} />
        <Button
          variant="primary"
          title="Confirm"
          onClick={handleSubmit}
          isDisabled={lang === initialLang}
        />
      </div>
    </div>
  );
};

export enum PATHES {
  // auth
  SIGN_IN = "/sign-in",
  SIGN_UP = "/sign-up",
  ENTER_CODE = "/enter-code",
  FORGOT_PASSWORD = "/forgot-password",
  CREATE_PASSWORD = "/create-password",

  // dashborad
  HOME = "/",
  CONTRACTS = "/contracts",
  HELP = "help",
  TRASH = "trash",

  // account
  ACCOUNT = "/account",
  SETTINGS = "/account/settings",
  MY_SIGNATURES = "/account/my-signatures",
  SUBSCRIPTION = "/account/subscription",

  // public
  TERMS = "/terms-of-service",
  PRIVACY_POLICY = "/privacy-policy",
}

import { FC } from "react";

import { Icon, Button } from "components/UI";

import styles from "./styles.module.scss";

type AreYouSureModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  info?: string;
  cancelButtonTitle?: string;
  confirmButtonTitle?: string;
};

export const AreYouSureModal: FC<AreYouSureModalProps> = ({
  onClose,
  onSubmit = () => {},
  info,
  cancelButtonTitle,
  confirmButtonTitle,
}) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <div className={styles.AreYouSureModal}>
      <Icon name="close" className={styles.close} action={onClose} />
      <h2 className={styles.title}>Are you sure?</h2>
      <p className={styles.info}>{info}</p>
      <div className={styles.buttons}>
        <Button
          variant="secondary"
          title={confirmButtonTitle || "Confirm"}
          onClick={handleSubmit}
          className={styles.confirmButton}
        />
        <Button
          variant="secondary"
          title={cancelButtonTitle || "Cancel"}
          onClick={onClose}
        />
      </div>
    </div>
  );
};

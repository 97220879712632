import { FC, useState, useEffect, MouseEvent } from "react";
import { useDraggable } from "@dnd-kit/core";
import OutsideClickHandler from "react-outside-click-handler";

import { useAppSelector, useAppDispatch, dashboard } from "store";
import { setIsFolderMoving } from "store/dashboard";

import { ContextMenu, RowActions, MovingModal } from "components";
import { Checkbox, Modal } from "components/UI";

import { cs, getMenuItems, toastSuccess } from "utils";
import { DataType } from "types";

import folderEmpty from "assets/img/icons/folder-empty-big.svg";
import filePreview from "assets/img/file_preview.png";

import styles from "./styles.module.scss";

interface ITableRowProps extends DataType {
  onChange: () => void;
  selectedItems: DataType[];
}

export const TableRow: FC<ITableRowProps> = ({
  name,
  onChange,
  selectedItems,
  type,
  childrenCount,
  createdAt,
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: name,
    data: selectedItems,
  });
  const dispatch = useAppDispatch();
  const { destinationFolder, isFolderMoving } = useAppSelector(dashboard);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isRowMenu, setIsRowMenu] = useState<boolean>(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (!transform) {
      window.scroll(0, 0);
    }
  }, [transform]);

  useEffect(() => {
    const handleClick = () => setIsClicked(false);
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const style = transform
    ? {
        transform: `translate3d(${transform.x + 150}px, ${
          transform.y + 100
        }px, 0)`,
      }
    : undefined;

  const handleRightClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsClicked(true);
    setPoints({
      x: e.pageX,
      y: e.pageY,
    });
  };

  const handleOver = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
    setIsRowMenu(false);
  };

  const handleSetRowMenu = (e: MouseEvent<HTMLElement>) => {
    setIsRowMenu(true);
    setPoints({
      x: e.pageX,
      y: e.pageY,
    });
  };

  const handleCancelMove = () => {
    dispatch(setIsFolderMoving(false));
  };

  const handleUploadSuccess = () => {
    handleCancelMove();
    toastSuccess("Files were successfully moved!");
  };

  const renderBasicRow = () => (
    <div className={styles.TableRow} style={{ marginBottom: "-85px" }}>
      <div className={styles.td}>
        <Checkbox
          name={name}
          onChange={onChange}
          isChecked={selectedItems.some((el) => el.name === name)}
        />
      </div>
      <div className={styles.td}>
        <div className={styles.name}>
          <img src={type === "FOLDER" ? folderEmpty : filePreview} alt={type} />
          <div className={styles.textName}>{name}</div>
        </div>
      </div>
      <div className={styles.td}>{type}</div>
      <div className={styles.td}>
        {childrenCount ? `${childrenCount} items` : ""}
      </div>
      <div className={styles.td}>{createdAt}</div>
    </div>
  );

  return (
    <OutsideClickHandler onOutsideClick={() => setIsClicked(false)}>
      <Modal isShowed={isFolderMoving} className={styles.MoveModal} noDarkness>
        <MovingModal
          onCancel={handleCancelMove}
          onSuccess={handleUploadSuccess}
        />
      </Modal>
      {transform && renderBasicRow()}
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        className={cs([
          styles.TableRow,
          !!transform && styles.graggable,
          selectedItems.length > 1 && styles.packet,
        ])}
        style={style}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        {transform ? (
          <>
            <img
              src={type === "FOLDER" ? folderEmpty : filePreview}
              alt={type}
            />
            <div className={styles.name}>{name}</div>
            <div className={styles.counter}>{selectedItems.length || 1}</div>
            <div className={styles.label}>move to: {destinationFolder}</div>
          </>
        ) : (
          <>
            <div className={styles.td}>
              <Checkbox
                name={name}
                onChange={onChange}
                isChecked={selectedItems.some((el) => el.name === name)}
              />
            </div>
            <div className={styles.td}>
              <div className={styles.name}>
                <img
                  src={type === "FOLDER" ? folderEmpty : filePreview}
                  alt={type}
                />
                <div className={styles.textName}>{name}</div>
              </div>
              {isHovered && type === "FOLDER" && (
                <RowActions
                  actions={["move", "rename"]}
                  additionalActions={["trash"]}
                  isRowMenu={isRowMenu}
                  points={points}
                  handleSetRowMenu={handleSetRowMenu}
                />
              )}
              {isHovered && ["PDF_CERTIFIED", "PDF"].includes(type) && (
                <RowActions
                  actions={["share", "move", "download"]}
                  additionalActions={["rename", "trash"]}
                  isRowMenu={isRowMenu}
                  points={points}
                  handleSetRowMenu={handleSetRowMenu}
                />
              )}
            </div>
            <div className={styles.td}>{type}</div>
            <div className={styles.td}>
              {childrenCount ? `${childrenCount} items` : ""}
            </div>
            <div className={styles.td}>{createdAt}</div>
            {isClicked && (
              <ContextMenu
                theme="light"
                items={getMenuItems(selectedItems, type)}
                top={points.y}
                left={points.x}
                isFixed
              />
            )}
          </>
        )}
      </div>
    </OutsideClickHandler>
  );
};

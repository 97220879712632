import { FC, useState, useEffect, useCallback } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector, auth } from "store";
import {
  setEditableEmail,
  setAuthenticated,
  setVerificationId,
} from "store/auth";
import { Auth } from "api";

import { Button } from "components/UI";
import { PATHES } from "constants/pathes";
import { cs, sleep } from "utils";

import styles from "./styles.module.scss";

export const EnterCode: FC = () => {
  const dispatch = useAppDispatch();
  const { email, editableEmail, forgotEmail } = useAppSelector(auth);
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>("");
  const [count, setCount] = useState<number>(59);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleVerify = useCallback(async () => {
    let res;
    if (forgotEmail) {
      res = await Auth.forgotPasswordVerify({
        email: forgotEmail,
        verificationCode: otp,
      });
    } else {
      res = await Auth.signUpVerify({ email, verificationCode: otp });
    }

    if (res?.verificationId) {
      dispatch(setVerificationId(res.verificationId));
      setSuccess(true);
    } else {
      setError(true);
    }
  }, [email, otp, dispatch, forgotEmail]);

  useEffect(() => {
    if (otp.length === 6 && (email || forgotEmail)) {
      handleVerify();
    } else if (otp.length < 6) {
      setError(false);
    }
  }, [otp.length, handleVerify, email, forgotEmail]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (editableEmail === "old" && success) {
        dispatch(setEditableEmail("new"));
        navigate(PATHES.FORGOT_PASSWORD);
      } else if (editableEmail === "new" && success) {
        dispatch(setAuthenticated(true));
        await sleep(0);
        navigate(PATHES.SETTINGS);
      } else if (success && !editableEmail) {
        navigate(PATHES.CREATE_PASSWORD);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [success, navigate, dispatch, editableEmail]);

  const getNewCode = async () => {
    setOtp("");
    setError(false);
    await Auth.checkEmail({ email });
    setCount(59);
  };

  return (
    <div className={styles.EnterCode}>
      <h1 className={styles.title}>Enter verification code</h1>
      <div className={styles.email}>Code was sent to: {email}</div>
      <div className={styles.codeWrap}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="tel"
          renderSeparator={false}
          renderInput={(props) => <input {...props} />}
          containerStyle={styles.containerStyle}
          inputStyle={cs([
            styles.inputStyle,
            success && styles.inputSuccess,
            error && styles.inputError,
          ])}
          shouldAutoFocus
        />
        {error && <div className={styles.error}>Code is incorrect</div>}
      </div>
      {count > 0 ? (
        <div className={styles.resend}>
          resend in:{" "}
          <span className={styles.counter}>
            {dayjs().set("minute", 0).set("second", count).format("mm:ss")}
          </span>
        </div>
      ) : (
        <Button
          variant="textBlack"
          size="sm"
          title="Resend code"
          onClick={getNewCode}
          className={styles.resendButton}
        />
      )}
    </div>
  );
};

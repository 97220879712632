import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const auth = (state: RootState) => state.auth;
export const dashboard = (state: RootState) => state.dashboard;

import { useState } from "react";

import { Button, Modal } from "components/UI";
import { CreateFolderModal } from "components";

import styles from "./styles.module.scss";

export const CreateFolder = () => {
  const [isCreateModal, setIsCreateModal] = useState(false);

  const handleOpenCreatModal = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreatModal = () => {
    setIsCreateModal(false);
  };

  return (
    <div className={styles.CreateFolder}>
      <Modal isShowed={isCreateModal} onClose={handleCloseCreatModal}>
        <CreateFolderModal onClose={handleCloseCreatModal} />
      </Modal>
      <Button
        title="Create Folder"
        variant="secondary"
        onClick={handleOpenCreatModal}
        iconStart="add-folder"
        iconStartType="stroke"
        className={styles.controlButton}
      />
    </div>
  );
};

import { useState, ChangeEvent } from "react";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import { useAppDispatch } from "store";
import { setIsFolderMoving } from "store/dashboard";

import { Search, Button } from "components/UI";
import { CustomTable, AccountButton, CreateFolder } from "components";
import { useUpload } from "hooks";

import styles from "./styles.module.scss";

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string>("");
  const { UploadModalWrapped, onUpload, renderDropzone, isDragActive } =
    useUpload();

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 8,
    },
  });

  const handleDragEnd = () => {
    dispatch(setIsFolderMoving(true));
  };

  const sensors = useSensors(pointerSensor);

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <UploadModalWrapped />
      <div className={styles.Dashboard}>
        <header className={styles.header}>
          <Search
            onChange={handleSearch}
            value={searchValue}
            className={styles.search}
            onClear={() => setSearchValue("")}
          />
          <div className={styles.controls}>
            <label className={styles.uploadButton}>
              <input
                className={styles.fileInput}
                type="file"
                accept=".pdf"
                onChange={onUpload}
                multiple
              />
              <Button
                title="Upload file"
                variant="primary"
                onClick={() => {}}
                className={styles.controlButton}
              />
            </label>
            <CreateFolder />
            <AccountButton />
          </div>
        </header>
        <CustomTable
          search={searchValue}
          renderDropzone={renderDropzone}
          isDragActive={isDragActive}
        />
      </div>
    </DndContext>
  );
};
